import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { isMobileUA } from 'dpl/shared/utils';

import BreederProfileLocationLinksGroupSkeleton from './BreederProfileLocationLinksGroupSkeleton';

const IS_MOBILE_UA = isMobileUA();

export default function BreederProfileLocationLinksGroup({
  breedName,
  linksData,
  linkType,
  header,
  wideLayout,
  limit,
  isStatePage,
  isFetching
}) {
  if (isFetching) {
    return (
      <BreederProfileLocationLinksGroupSkeleton
        wideLayout={wideLayout}
        limit={limit}
        isStatePage={isStatePage}
      />
    );
  }

  const limitedLinksData = limit
    ? linksData.data.slice(0, limit)
    : linksData.data;

  return (
    <div className="BreederProfileLocationLinksGroup f2 f3-sm">
      <h5 className="mb4 mb6-lg f3 f4-md lh-normal">{header}</h5>
      <ul className={classnames('row slim-gutters', { mb18: wideLayout })}>
        {limitedLinksData.map(linkLocation => (
          <li
            key={linkLocation.name}
            className={classnames('mb2 col-12', {
              BreederProfileLocationLinksGroup__links:
                // For state pages, we want the links to each have their own line in mobile due to the long text
                (wideLayout && !isStatePage) || (isStatePage && !IS_MOBILE_UA),
              'col-md-6': !isStatePage
            })}
          >
            <a
              href={linkLocation[`${linkType}_page_url`]}
              className="dark-gray"
              key={linkLocation.name}
            >
              {isStatePage
                ? `${breedName} puppies in ${linkLocation.name}`
                : linkLocation.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

BreederProfileLocationLinksGroup.propTypes = {
  breedName: PropTypes.string,
  linksData: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object).isRequired
  }).isRequired,
  header: PropTypes.string.isRequired,
  linkType: PropTypes.string,
  wideLayout: PropTypes.bool,
  limit: PropTypes.number,
  isStatePage: PropTypes.bool,
  isFetching: PropTypes.bool
};

BreederProfileLocationLinksGroup.defaultProps = {
  breedName: '',
  wideLayout: false,
  linkType: 'city',
  limit: null,
  isStatePage: false,
  isFetching: false
};
