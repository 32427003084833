import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { resetParams, pushParams } from 'dpl/actions/query_params';
import {
  ANALYTICS,
  PUPPY_ID,
  LITTER_ID,
  DOG_ID
} from 'dpl/constants/query_params';
import { PHOTO_MEDIA_CATEGORY_SLUGS } from 'dpl/shared/constants/breeder_app';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { dogModalsLitterResourceDefintion } from 'dpl/dog_modals/utils/queryDefinitions';
import { deliveryMethodsResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import { getOrderedParents } from 'dpl/shared/utils/breeder_app';
import { sendRudderstackEvent } from 'dpl/util/analytics';
import { ANALYTICS_EVENTS } from 'dpl/constants/analytics';
import { openPuppyPage } from 'dpl/puppy_page/utils/helpers';

const { CURRENT_LITTER, PAST_LITTER } = PHOTO_MEDIA_CATEGORY_SLUGS;
const PHOTO_CAPTIONS = {
  [CURRENT_LITTER]: 'Current Litter',
  [PAST_LITTER]: 'Past Puppies from Parents'
};

export default function useLitterModal() {
  const dispatch = useDispatch();

  const currentLitterId = useSelector(({ queryParams }) =>
    Number(queryParams[LITTER_ID])
  );
  const queryPuppyId = useSelector(({ queryParams }) =>
    Number(queryParams[PUPPY_ID])
  );

  const isLitterModalOpen = Boolean(currentLitterId && !queryPuppyId);

  const litterData = useResourceQuery({
    ...dogModalsLitterResourceDefintion(currentLitterId),
    enabled: Boolean(currentLitterId)
  });

  const litter = litterData.value.data;
  const breederProfile = litter.breeder_profile;

  const deliveryMethodsData = useResourceQuery({
    ...deliveryMethodsResourceDefinition(breederProfile.id),
    enabled: Boolean(breederProfile.id)
  });

  const parents = getOrderedParents(litter.parents);
  const { litter_ids: litterIds } = breederProfile;
  const litterImages = litter.photographs.map(p => ({
    ...p,
    caption: PHOTO_CAPTIONS[p.media_category_slug]
  }));

  const currentLitterIdx = litterIds.findIndex(id => id === Number(litter.id));

  if (parents.length) {
    // HACK: special case for first image - split sire + dam profile picture
    const [sire, dam] = parents;
    litterImages.unshift({
      caption: `${sire.name} & ${dam.name} (Parents)`,
      id: `${sire.profile_photo_url}-${dam.profile_photo_url}`,
      urls: [sire.profile_photo_url, dam.profile_photo_url]
    });
  }

  useEffect(() => {
    if (!litterData.isSuccess || !isLitterModalOpen) {
      return;
    }

    const props = {
      breeder_profile_id: litter.breeder_profile.id,
      litter_id: currentLitterId,
      available_puppies: litter.puppies.filter(p => p.available).length,
      reserved_puppies: litter.puppies.filter(p => p.reserved).length,
      litter_status: litter.status,
      photos: litter.photographs.length
    };
    sendRudderstackEvent(ANALYTICS_EVENTS.LITTER_MODAL_VIEWED, props);
  }, [currentLitterId, litterData.isSuccess, isLitterModalOpen]);

  function resetLitterModalParams() {
    dispatch(resetParams(LITTER_ID, ANALYTICS, { hash: true }));
  }

  function handlePuppyClick(puppyId) {
    openPuppyPage({ puppyId });
  }

  function handleLitterClick(litterId) {
    resetLitterModalParams();
    dispatch(pushParams({ [LITTER_ID]: litterId }, { hash: true }));
  }

  function handleParentClick(dogId) {
    resetLitterModalParams();
    dispatch(pushParams({ [DOG_ID]: dogId }, { hash: true }));
  }

  return {
    litter,
    litterImages,
    included: litter.extras?.map(i => i.name),
    previousLitterId: litterIds[currentLitterIdx - 1],
    nextLitterId: litterIds[currentLitterIdx + 1],
    deliveryMethodsData,
    isSuccess: litterData?.isSuccess,
    isLitterModalOpen,
    onPuppyClick: handlePuppyClick,
    onParentClick: handleParentClick,
    onLitterClick: handleLitterClick,
    onModalClose: resetLitterModalParams
  };
}
