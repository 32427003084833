import { ANALYTICS_VIEW_FEATURE_LOCATIONS } from 'dpl/constants/analytics';

export const TAB_KEYS = {
  OVERVIEW: 'overview',
  DOGS: 'dogs',
  UPDATES: 'updates',
  /* TODO: Uncomment when ready */
  // PROGRAM: 'program',
  STORIES: 'stories'
};

export const TAB_NAMES = {
  [TAB_KEYS.OVERVIEW]: 'Overview',
  [TAB_KEYS.DOGS]: 'Litters & puppies',
  [TAB_KEYS.UPDATES]: 'Updates',
  /* TODO: Uncomment when ready */
  // [TAB_KEYS.PROGRAM]: 'About the program',
  [TAB_KEYS.STORIES]: 'Owner stories'
};

export const TAB_VIEW_FEATURE_LOCATIONS = {
  [TAB_KEYS.OVERVIEW]: ANALYTICS_VIEW_FEATURE_LOCATIONS.OVERVIEW_TAB,
  [TAB_KEYS.DOGS]: ANALYTICS_VIEW_FEATURE_LOCATIONS.LITTERS_AND_PUPPIES_TAB,
  [TAB_KEYS.UPDATES]: ANALYTICS_VIEW_FEATURE_LOCATIONS.UPDATES_TAB,
  [TAB_KEYS.STORIES]: ANALYTICS_VIEW_FEATURE_LOCATIONS.OWNER_STORIES_TAB
};

export const PAGE_CATEGORIES = {
  [TAB_KEYS.OVERVIEW]: 'breeder-profile',
  [TAB_KEYS.DOGS]: 'breeder-profile-tab-litters-and-puppies',
  [TAB_KEYS.UPDATES]: 'breeder-profile-tab-updates',
  [TAB_KEYS.STORIES]: 'breeder-profile-tab-owner-stories'
};
