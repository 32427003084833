import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'validate.js';

import Button from 'dpl/common/design_system/Button';
import BreederProfileOwnerStoriesTabSkeleton from 'dpl/components/BreederProfile/Show/skeletons/BreederProfileOwnerStoriesTabSkeleton';
import OwnerStory from 'dpl/components/OwnerStory';
import { breederProfileBreederTestimonialIndexResourceDefinition } from 'dpl/shared/breeder/utils/queryDefinitions';
import useInfiniteCollectionQuery from 'dpl/shared/fetching/hooks/useInfiniteCollectionQuery';
import { isGreaterThanBreakpoint } from 'dpl/util/grid';

export const STORIES_PER_PAGE = 6;

export default function BreederProfileOwnerStoriesTabContainer({ className }) {
  const { breederFirstName, breederProfileId } = useSelector(
    ({ server }) => server
  );

  const {
    infiniteScrollItems,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetching
  } = useInfiniteCollectionQuery(
    breederProfileBreederTestimonialIndexResourceDefinition(breederProfileId, {
      pageNumber: 1,
      perPage: STORIES_PER_PAGE
    })
  );

  const columns = useMemo(() => {
    return infiniteScrollItems.reduce(
      (acc, story, index) => {
        const key = isGreaterThanBreakpoint('md') ? index % 2 : 0;
        acc[key].push(story);
        return acc;
      },
      [[], []]
    );
  }, [infiniteScrollItems]);

  if (isLoading) {
    return <BreederProfileOwnerStoriesTabSkeleton className={className} />;
  }

  if (!isLoading && infiniteScrollItems.length === 0) {
    return null;
  }

  return (
    <section
      className={classnames(
        'BreederProfileOwnerStoriesTabContainer container-xxl mb12 mb16-md',
        className
      )}
    >
      <div className="mb10 mb12-md">
        <div className="grid gap-8 mb5 mb6-md">
          <h3 className="font-20 md:font-24 lh-title">
            Verified owner stories
          </h3>
          <p className="font-16 md:font-18 secondary">
            Hear what other owners say about working with {breederFirstName} to
            bring home their puppies.
          </p>
        </div>
      </div>
      <ul className="grid lg:grid-cols-2 justify-between gap-80">
        {columns
          .filter(column => !isEmpty(column))
          .map((stories, colIndex) => (
            <li key={colIndex}>
              {stories.map((story, index) => (
                <OwnerStory
                  key={story.id}
                  className={classnames({
                    'pb6 pb12-md': index !== stories.length - 1,
                    'pt6 pt12-md bt b--default': index
                  })}
                  date={story.formatted_submitted_at}
                  description={story.experience}
                  gallery={story.photographs}
                  user={story.buyer_profile}
                />
              ))}
            </li>
          ))}
      </ul>
      {hasNextPage && (
        <Button
          className="font-16 stone-700 ph16 pv3 w-100 w-auto-m mt6 mt12-md"
          variant="outline"
          onClick={fetchNextPage}
          disabled={isFetching}
        >
          Show more owner stories
        </Button>
      )}
    </section>
  );
}

BreederProfileOwnerStoriesTabContainer.propTypes = {
  className: PropTypes.string
};

BreederProfileOwnerStoriesTabContainer.defaultProps = {
  className: null
};
