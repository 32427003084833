import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SmartImage from 'dpl/components/SmartImage';

import somethingPendingSvg from 'dpl/assets/something_pending.svg';

export default function ImagePlaceholder({ imgSize, className, caption }) {
  return (
    <div
      className={classnames(
        'ImagePlaceholder flex flex-column w-100 h-100 items-center justify-center pv12 ph12',
        className
      )}
    >
      <div className="flex overflow-hidden br-100">
        <SmartImage
          src={somethingPendingSvg}
          width={imgSize}
          height={imgSize}
        />
      </div>
      {caption && <h6 className="heading-6 mt6 tc">{caption}</h6>}
    </div>
  );
}

ImagePlaceholder.propTypes = {
  imgSize: PropTypes.string,
  className: PropTypes.string,
  caption: PropTypes.string
};

ImagePlaceholder.defaultProps = {
  imgSize: '100px',
  className: 'bg-white',
  caption: null
};
