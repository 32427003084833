import React, { Fragment } from 'react';

import { isLessThanBreakpoint } from 'dpl/util/grid';
import { ABOUT_US_PATH } from 'dpl/shared/constants/urls';
import Icon from 'dpl/common/components/Icon';

const IS_LT_MD = isLessThanBreakpoint('md');
const ICON_SIZE = IS_LT_MD ? '24px' : '32px';

const BANNER_ITEMS = [
  {
    icon: 'fetch-globe',
    text: 'A nationwide network of certified breeders'
  },
  {
    icon: 'fetch-message',
    text: 'Direct connections with breeders'
  },
  {
    icon: 'fetch-card-lock',
    text: 'Safe payments, backed by our guarantee'
  }
];

function Divider() {
  return <div className="b--default o-40 bb bb-0-l br-m br-l mv0 mv5-md" />;
}

export default function GoodDogValueBanner() {
  return (
    <div className="GoodDogValueBanner bg-stone-700 ph5 pv10 pv12-md">
      <div className="container-xxl flex flex-column items-center gap-8 md:gap-32">
        <p className="white o-80 font-18 fw-medium">What is Good Dog?</p>
        <div className="flex flex-column flex-md-row">
          {BANNER_ITEMS.map((item, idx) => (
            <Fragment key={idx}>
              <div className="flex flex-column flex-auto-ns flex-basis-0-l items-center tc gap-12 md:gap-16 pv5 pv0-md ph10-md">
                <Icon
                  name={item.icon}
                  width={ICON_SIZE}
                  height={ICON_SIZE}
                  className="white"
                />
                <p className="font-serif font-24 md:font-32 fw-medium lh-title white">
                  {item.text}
                </p>
              </div>
              {idx < BANNER_ITEMS.length - 1 && <Divider />}
            </Fragment>
          ))}
        </div>
        <a
          href={ABOUT_US_PATH}
          target="_blank"
          rel="noopener noreferrer"
          className="white o-80 underline font-14 md:font-18 fw-medium fw-normal-ns"
        >
          Learn more about Good Dog
        </a>
      </div>
    </div>
  );
}
