import React from 'react';
import PropTypes from 'prop-types';

import { BreederProfileDeliverMethodPropTypes } from 'dpl/shared/utils/customPropTypes';
import { DELIVERY_PATH } from 'dpl/shared/constants/urls';
import BadgeList from 'dpl/common/components/BadgeList';
import Icon from 'dpl/common/components/Icon';

// this component is only used in the litter modal
export default function BreederProfileDeliveryOptions({ deliveryMethodsData }) {
  const { delivery_description: deliveryDescription, methods } =
    deliveryMethodsData.value.data;

  return (
    <div className="BreederProfileDeliveryOptions">
      <div className="mb2">
        <BadgeList
          titles={methods.map(({ delivery_method: { name } }) => name)}
        />
      </div>
      <p className="mb2">{deliveryDescription}</p>
      <a href={DELIVERY_PATH} className="primary fw-medium flex items-center">
        Learn More About Delivery
        <Icon className="ml2" name="fat-arrow-right" />
      </a>
    </div>
  );
}

BreederProfileDeliveryOptions.propTypes = {
  deliveryMethodsData: PropTypes.shape({
    value: PropTypes.shape(BreederProfileDeliverMethodPropTypes).isRequired
  }).isRequired
};
