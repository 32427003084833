import React from 'react';
import PropTypes from 'prop-types';

import { USER_TYPES } from 'dpl/shared/constants/shared';
import SignupFormContainer from 'dpl/containers/SignupFormContainer';

export default function BreederProfileQuestionModalSignupForm({
  onAuth,
  onToggle
}) {
  return (
    <div className="BreederProfileQuestionModalSignupForm">
      <div className="ph10 ph18-lg">
        <SignupFormContainer onAuth={onAuth} userType={USER_TYPES.BUYER} />
      </div>
      <div className="pv6 tc bt b--light-gray">
        <span>Already have an account?</span>&nbsp;
        <button
          className="BreederProfileQuestionModalSignupForm__toggle primary link--primary"
          onClick={onToggle}
          type="button"
        >
          Log in
        </button>
      </div>
    </div>
  );
}

BreederProfileQuestionModalSignupForm.propTypes = {
  onAuth: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired
};
