import React from 'react';

import BreederProfileTabContent from 'dpl/components/BreederProfile/Show/BreederProfileTabContent';
import BreederProfileTabs from 'dpl/components/BreederProfile/Show/BreederProfileTabs';

export default function BreederProfileLayoutContainer() {
  return (
    <div className="BreederProfileLayoutContainer">
      <BreederProfileTabs />
      <BreederProfileTabContent />
    </div>
  );
}
