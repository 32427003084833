import React from 'react';

import SkeletonBlock from 'dpl/common/design_system/SkeletonBlock';

export default function BreederProfileAdoptionProcessSkeleton() {
  return (
    <div className="BreederProfileAdoptionProcessSkeleton bg-subtle-light-gray pv12 pv20-md font-16 md:font-18">
      <div className="container-xxl">
        <div className="row wide-gutters">
          <div className="col-md-5">
            <SkeletonBlock
              className="mb4 mb6-md font-24 md:font-40"
              height="1em"
              width="100%"
            />
            <div className="mb10">
              <SkeletonBlock className="lh-body" height="10em" width="100%" />
            </div>
          </div>
          <div className="col-md-6 offset-md-1">
            <div className="pb6">
              <SkeletonBlock
                className="font-18 md:font-24 mb1 mb4-md"
                height="1em"
                width="4em"
              />
              <SkeletonBlock className="mb4" height="3em" width="100%" />
            </div>
            <div className="pt6 bt b--gray-300">
              <SkeletonBlock
                className="font-18 md:font-24 mb1 mb4-md"
                height="1em"
                width="8em"
              />
              <SkeletonBlock className="lh-body" height="4em" width="100%" />
              <SkeletonBlock className="mt8 mt6-md" height="3em" width="100%" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
