import React from 'react';

import SkeletonBlock from 'dpl/common/design_system/SkeletonBlock';
import { makeSequence } from 'dpl/shared/utils';

export default function BreederProfileDeliverySkeleton() {
  return (
    <div className="BreederProfileDeliverySkeleton bg-subtle-light-gray font-16 md:font-18">
      <div className="container-xxl">
        <div className="pv12 pv20-md">
          <SkeletonBlock
            className="mb4 font-24 md:font-32"
            height="1em"
            width="16em"
          />
          <div className="row wide-gutters">
            <div className="col-md-5">
              <SkeletonBlock className="mb10 mb5-md" height="1em" width="90%" />
            </div>
          </div>
          <div className="row wide-gutters">
            <div className="col-md-5">
              <SkeletonBlock
                className="BreederProfileDeliverySkeleton__map mb10"
                width="100%"
              />
            </div>
            <div className="col-md-6 offset-md-1">
              <div className="flex flex-column gap-48 md:gap-64">
                {makeSequence(2).map(index => (
                  <div key={index}>
                    <SkeletonBlock
                      className="font-18 md:font-24 mb4 mb6-md"
                      height="1em"
                      width="70%"
                    />
                    <SkeletonBlock className="mb4" height="2em" width="100%" />
                  </div>
                ))}
              </div>
              <SkeletonBlock className="mt4 mt6-md" height="3em" width="100%" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
