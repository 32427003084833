import React from 'react';

import { isLessThanBreakpoint } from 'dpl/util/grid';
import SkeletonBlock from 'dpl/common/design_system/SkeletonBlock';

const IS_LT_MD = isLessThanBreakpoint('md');

export default function BreederProfileAboutPuppiesSkeleton() {
  const sections = [
    { className: 'pb6', width: '8em' },
    { className: 'pv6 bb bt b--gray-300', width: '6em' },
    { className: 'pt6', width: '4em' }
  ];

  return (
    <div className="BreederProfileAboutPuppiesSkeleton bg-subtle-light-gray font-16 md:font-18">
      <div className="container-xxl">
        <div className="pv12 pv20-md bb bt b--gray-300">
          <div className="row wide-gutters">
            <div className="col-md-5">
              <SkeletonBlock
                className="font-24 md:font-32"
                height="1em"
                width="50%"
              />
              <SkeletonBlock
                className="mt6 mb6 m12-md"
                height="200px"
                width={IS_LT_MD ? '160px' : '240px'}
              />
            </div>
            <div className="col-md-6 offset-md-1">
              {sections.map((section, index) => (
                <div key={index} className={section.className}>
                  <SkeletonBlock
                    className="font-18 md:font-24 mb1 mb4-md"
                    height="1em"
                    width={section.width}
                  />
                  <SkeletonBlock height="4em" width="100%" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
