import { ANALYTICS_EVENTS } from 'dpl/constants/analytics';
import { sendAnalyticsEvent } from 'dpl/util/analytics';

export function trackBuyerApplicationQuestionView(
  title,
  properties,
  name = title
) {
  sendAnalyticsEvent(ANALYTICS_EVENTS.BUYER_APPLICATION_QUESTION_VIEWED, {
    questionName: name,
    questionTitle: title,
    ...properties
  });
}
