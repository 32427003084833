import React from 'react';

import SkeletonBlock from 'dpl/common/design_system/SkeletonBlock';
import { makeSequence } from 'dpl/shared/utils';

export default function BreederProfileRelatedBreedsSkeleton() {
  return (
    <div className="BreederProfileRelatedBreedsSkeleton">
      <SkeletonBlock
        className="font-24 md:font-40 mb6 mb8-md"
        height="1em"
        width="7em"
      />
      <ul className="flex gap-12 md:gap-24 overflow-hidden mb6 mb20-lg">
        {makeSequence(3).map(index => (
          <li
            key={index}
            className="flex-auto flex flex-column ba b--subtle-gray br3"
            style={{ minWidth: 335 }}
          >
            <div className="aspect-ratio aspect-ratio--1x1">
              <div className="aspect-ratio--object">
                <SkeletonBlock className="h-100" />
              </div>
            </div>
            <div className="flex flex-column items-center pv4 ph4">
              <SkeletonBlock className="font-24" height="1em" width="6em" />
              <SkeletonBlock
                className="font-16 mt2"
                height="1em"
                width="12em"
              />
            </div>
            <div className="bt b--subtle-gray tc pv4 ph4">
              <SkeletonBlock className="dib font-16" height="1em" width="8em" />
            </div>
          </li>
        ))}
      </ul>
      <SkeletonBlock className="font-28 mb4 mb6-lg" height="1em" width="12em" />
      <ul className="grid grid-cols-2 lg:grid-cols-5 gap-12">
        {[7, 8, 6, 7, 8, 6, 7, 8, 6, 7].map((width, index) => (
          <SkeletonBlock
            key={index}
            className="font-16 md:font-18"
            width={`${width}em`}
            height="1em"
          />
        ))}
      </ul>
    </div>
  );
}
