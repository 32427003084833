import PropTypes from 'prop-types';
import React, { useReducer } from 'react';
import { isEmpty } from 'validate.js';

import Button from 'dpl/common/design_system/Button';

import BreederProfileLitterPuppyCard, {
  PuppyPropTypes
} from '../BreederProfileLitterPuppyCard';

const COLLAPSED_PUPPIES_COUNT = 5;

export default function BreederProfileLitterList({
  breederProfileId,
  litterName,
  puppies: listings,
  description
}) {
  const isCollapsible = listings.length > COLLAPSED_PUPPIES_COUNT;

  const [isCollapsed, toggleIsCollapsed] = useReducer(
    collapsed => !collapsed,
    isCollapsible
  );

  const puppies = isCollapsed
    ? listings.slice(0, COLLAPSED_PUPPIES_COUNT)
    : listings;

  if (isEmpty(puppies)) {
    return null;
  }

  return (
    <div className="BreederProfileLitterList container-xxl">
      <div className="grid gap-8 mb5 mb6-md">
        <h3 className="font-20 md:font-24 lh-title">{litterName}’s litter</h3>
        {description && (
          <p className="font-16 md:font-18 secondary">{description}</p>
        )}
      </div>
      <ul className="grid gap-16">
        {puppies.map(puppy => (
          <li key={puppy.id}>
            <BreederProfileLitterPuppyCard
              puppy={puppy}
              breederProfileId={breederProfileId}
            />
          </li>
        ))}
      </ul>
      {isCollapsible && (
        <Button
          className="font-16 stone-700 w-100 mt4"
          variant="outline"
          size="default"
          onClick={toggleIsCollapsed}
        >
          {isCollapsed ? 'See more puppies' : 'See less puppies'}
        </Button>
      )}
    </div>
  );
}

BreederProfileLitterList.propTypes = {
  breederProfileId: PropTypes.string.isRequired,
  litterName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  puppies: PropTypes.arrayOf(PuppyPropTypes).isRequired
};
