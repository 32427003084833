export function nearbyCitiesResourceDefinition(
  breedId,
  citySlug,
  breederProfileId
) {
  return {
    url: '/api/search/top_cities.json',
    defaultValue: {
      data: []
    },
    params: {
      breed_id: breedId,
      city_slug: citySlug,
      breeder_profile_id: breederProfileId
    }
  };
}

export function biggestCitiesResourceDefinition(breedId, stateName) {
  return {
    url: '/api/search/top_cities.json',
    defaultValue: {
      data: []
    },
    params: {
      breed_id: breedId,
      state_name: stateName
    }
  };
}

export function nearbyStatesResourceDefinition(breedId, stateName) {
  return {
    url: '/api/search/nearby_states.json',
    defaultValue: {
      data: []
    },
    params: {
      breed_id: breedId,
      state_name: stateName
    }
  };
}

export function breedRelationsForStateResourceDefinition(
  breedId,
  nonStandard = false,
  stateSlug
) {
  return {
    url: `/api/breeds/${breedId}/breed_relations/for_state.json`,
    defaultValue: {
      stateName: null,
      data: []
    },
    params: {
      non_standard: nonStandard,
      state_slug: stateSlug
    }
  };
}

export function popularCitiesResourceDefinition(breedId) {
  return {
    url: '/api/search/popular_cities.json',
    defaultValue: {
      data: []
    },
    params: {
      breed_id: breedId
    }
  };
}

export function popularStatesResourceDefinition(breedId) {
  return {
    url: '/api/search/popular_states.json',
    defaultValue: {
      data: []
    },
    params: {
      breed_id: breedId
    }
  };
}

export function breedOverviewPagesResourceDefinition(limit) {
  return {
    url: '/api/search/breed_overview_pages.json',
    defaultValue: {
      data: []
    },
    params: {
      limit
    }
  };
}
