import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { breederProfileBreedsWithHealthInfoResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import { groupBy } from 'dpl/shared/utils';
import ClampedMultiLineText from 'dpl/common/components/ClampedMultiLineText';
import Badge from 'dpl/common/design_system/Badge';
import {
  BADGE_COLOR_OPTIONS,
  BADGE_VARIANT_OPTIONS
} from 'dpl/common/design_system/utils/constants';
import BreederProfileBreedFilter from 'dpl/components/BreederProfile/Show/BreederProfileBreedFilter';
import Icon from 'dpl/common/components/Icon';
import { isLessThanBreakpoint } from 'dpl/util/grid';
import SmartLink from 'dpl/components/SmartLink';
import BreederProfileHealthTestsSkeleton from 'dpl/components/BreederProfile/Show/skeletons/BreederProfileHealthTestsSkeleton';

const IS_LT_MD = isLessThanBreakpoint('md');
const ICON_SIZE = IS_LT_MD ? '24px' : '48px';

const HEALTH_TEST_CATEGORY_ICONS = {
  dna: 'fetch-light-dna',
  elbow: 'fetch-light-bone-square',
  eye: 'fetch-light-eye-open',
  'general-health-tests': 'fetch-light-bone-square-alt',
  heart: 'fetch-light-heart-rate',
  hips: 'fetch-light-clipboard-paw',
  knee: 'fetch-light-bone',
  thyroid: 'fetch-light-dog-health'
};

export function BreederProfileHealthBadgeSection({
  breederDisplayName,
  breedHealthTier,
  learnMoreUrl,
  pluralizedBreedName
}) {
  const displayHealthTier = Boolean(breedHealthTier);

  return (
    <div className="bg-stroll-light br3 ba b--gray-300 pv8 ph4 pv12-md ph12-md">
      <div
        className={classnames('grid items-center gap-16 forest-dark-900', {
          'md:grid-cols-2 md:gap-64': displayHealthTier
        })}
      >
        {displayHealthTier && (
          <div>
            <Badge
              className="mb6 fw-medium"
              color={BADGE_COLOR_OPTIONS.stroll}
              variant={BADGE_VARIANT_OPTIONS.bright}
            >
              Breeder-Reported Testing
            </Badge>
            <p className="font-32 md:font-40 font-serif fw-medium lh-title">
              {breedHealthTier?.name} level
            </p>
          </div>
        )}
        <p className="font-18">
          {breederDisplayName} reports to performing the health tests below on
          their breeding dogs. Ask your breeder about the tests performed on the
          parents of your litter.&nbsp;
          <SmartLink className="underline" to={learnMoreUrl} target="_blank">
            Learn more
          </SmartLink>
          &nbsp;about health testing for {pluralizedBreedName}.
        </p>
      </div>
    </div>
  );
}

BreederProfileHealthBadgeSection.propTypes = {
  breederDisplayName: PropTypes.string.isRequired,
  breedHealthTier: PropTypes.shape({
    name: PropTypes.string.isRequired,
    level: PropTypes.number
  }),
  learnMoreUrl: PropTypes.string.isRequired,
  pluralizedBreedName: PropTypes.string.isRequired
};

BreederProfileHealthBadgeSection.defaultProps = {
  breedHealthTier: null
};

export default function BreederProfileHealthTestsSectionContainer() {
  const { breederDisplayName, breederProfileId } = useSelector(
    ({ server }) => server
  );
  const [selectedFilterIdx, setSelectedFilterIdx] = useState(0);

  const {
    isFetching,
    value: {
      data: { breeds }
    }
  } = useResourceQuery(
    breederProfileBreedsWithHealthInfoResourceDefinition(breederProfileId)
  );

  if (isFetching) {
    return <BreederProfileHealthTestsSkeleton />;
  }

  const breedsWithHealthInfo = breeds.flatMap(breed => {
    const { preferences, ...rest } = breed;

    if (preferences && preferences.length) {
      return preferences
        .filter(pref => pref.health_tests.length > 0)
        .map(pref => ({
          ...rest,
          breed_name: `${pref.description} ${breed.breed_name}`,
          pluralized_breed_name: `${pref.description} ${breed.pluralized_breed_name}`,
          health_tests: pref.health_tests,
          health_tier: pref.health_tier
        }));
    }

    return breed.health_tests.length > 0 ? [breed] : [];
  });

  if (breedsWithHealthInfo.length === 0) {
    return null;
  }

  function handleBreedClick(idx) {
    setSelectedFilterIdx(idx);
  }

  const selectedBreed = breedsWithHealthInfo[selectedFilterIdx];

  const {
    health_tests: healthTests,
    pluralized_breed_name: pluralizedBreedName,
    health_tier: breedHealthTier,
    health_testing_guide_path: learnMoreUrl
  } = selectedBreed;

  return (
    <section className="BreederProfileHealthTestsSection bg-subtle-light-gray">
      <div className="container-xxl">
        <div className="pv12 pv20-md bt b--gray-300">
          <h3 className="font-24 md:font-32 lh-title mb8">
            Parent health testing
          </h3>
          <BreederProfileBreedFilter
            className="mb6 mb14-md"
            items={breedsWithHealthInfo.map(b => ({
              label: b.breed_name,
              photo_url: b.breed_cover_photo
            }))}
            onFilterClick={handleBreedClick}
            selectedFilterIdx={selectedFilterIdx}
          />
          {selectedBreed && (
            <>
              <BreederProfileHealthBadgeSection
                breederDisplayName={breederDisplayName}
                breedHealthTier={breedHealthTier}
                learnMoreUrl={learnMoreUrl}
                pluralizedBreedName={pluralizedBreedName}
              />
              <ul className="grid md:grid-cols-2 md:gap-x-64 mt12-md">
                {Object.values(
                  groupBy(healthTests, 'health_test_category.id')
                ).map((tests, idx) => {
                  const category = tests[0].health_test_category;

                  return (
                    <li
                      key={idx}
                      className={classnames(
                        'flex flex-column flex-md-row items-center-m gap-16 md:gap-24 pv6 pv8-md bt b--gray-300',
                        {
                          bn: idx === 0,
                          'bn-m bn-l': idx === 1
                        }
                      )}
                    >
                      <div className="bg-white ph3 pv3 ph5-md pv5-md br-100 self-start secondary">
                        <Icon
                          name={HEALTH_TEST_CATEGORY_ICONS[category.icon]}
                          height={ICON_SIZE}
                          width={ICON_SIZE}
                        />
                      </div>
                      <div>
                        <ClampedMultiLineText
                          className="font-serif fw-medium font-18 md:font-20 lh-title mb2"
                          numOfLines={3}
                          lineHeight={IS_LT_MD ? 22.5 : 25}
                          text={tests.map(({ name }) => name).join(', ')}
                          moreText="Read more"
                          lessText="Show less"
                          moreButtonClassName="stone-700"
                          fadeToBackgroundColor="#FAFAFA"
                          fadeToButton
                        />

                        <ClampedMultiLineText
                          className="font-16 secondary lh-body"
                          numOfLines={5}
                          lineHeight={22.4}
                          text={category.description}
                          moreText="Read more"
                          lessText="Show less"
                          moreButtonClassName="stone-700"
                          fadeToBackgroundColor="#FAFAFA"
                          fadeToButton
                        />
                      </div>
                    </li>
                  );
                })}
              </ul>
            </>
          )}
        </div>
      </div>
    </section>
  );
}
