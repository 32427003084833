import classnames from 'classnames';
import React from 'react';

import SkeletonBlock from 'dpl/common/design_system/SkeletonBlock';
import { makeSequence } from 'dpl/shared/utils';
import { isLessThanBreakpoint } from 'dpl/util/grid';

const IS_LT_MD = isLessThanBreakpoint('md');
const IS_LT_LG = isLessThanBreakpoint('lg');

export default function BreederProfileCurrentLittersSectionSkeleton() {
  return (
    <div className="BreederProfileCurrentLittersSectionSkeleton container-xxl flex flex-column gap-48">
      {makeSequence(3).map(rowIndex => (
        <div key={rowIndex}>
          <div className="mb5 mb6-md">
            <SkeletonBlock
              className="font-20 md:font-24 mb4"
              height="1em"
              width="8em"
            />
            <SkeletonBlock
              className="font-16 md:font-18"
              height="1em"
              width="14em"
            />
          </div>
          <div
            className={classnames('flex gap-16', {
              'flex-column': IS_LT_MD
            })}
          >
            {makeSequence(IS_LT_LG ? 2 : 3).map(colIndex => (
              <SkeletonBlock
                key={colIndex}
                className="br3"
                height={IS_LT_LG ? '424px' : '504px'}
                width="100%"
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
