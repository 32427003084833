import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { deliveryMethodsResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import { TRANSPORT_CATEGORY } from 'dpl/breeder_search_results/utils/constants';
import Drawer, {
  DrawerItem,
  DrawerItemContent,
  DrawerItemTitle
} from 'dpl/common/components/Drawer';
import BreederProfileDeliverySkeleton from 'dpl/components/BreederProfile/Show/skeletons/BreederProfileDeliverySkeleton';

export default function BreederProfileDeliverySectionContainer() {
  const { breederFirstName, breederProfileId } = useSelector(
    ({ server }) => server
  );

  const {
    isFetching,
    value: { data: deliveryMethods }
  } = useResourceQuery(deliveryMethodsResourceDefinition(breederProfileId));

  const {
    delivery_process_description: deliveryProcessDescription,
    delivery_method_sections: deliveryMethodSections,
    meet_halfway: meetHalfway,
    methods,
    facility
  } = deliveryMethods;

  if (isFetching) {
    return <BreederProfileDeliverySkeleton />;
  }

  const pickUpTransportation = deliveryMethodSections?.find(
    t => t.type === TRANSPORT_CATEGORY.PICKUP
  );
  const dropOffTransportation = deliveryMethodSections?.find(
    t => t.type === TRANSPORT_CATEGORY.DROPOFF
  );

  return (
    <section className="BreederProfileDeliverySection bg-subtle-light-gray font-16 md:font-18">
      <div className="container-xxl">
        <div className="pv12 pv20-md">
          <h3 className="mb4 font-24 md:font-32 lh-title">
            Bringing your puppy home
          </h3>
          <div className="row wide-gutters">
            <div className="col-md-5">
              <p className="mb10 mb5-md">
                {breederFirstName} offers options to help you easily get your
                new puppy home.
              </p>
            </div>
          </div>
          <div className="row wide-gutters">
            <div className="col-md-5">
              {facility.latitude && facility.longitude && (
                <div className="BreederProfileDeliverySection__map br3 overflow-hidden mb10">
                  <iframe
                    title="breeder-location-map"
                    style={{ border: 0, height: '100%', width: '100%' }}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    src={`https://www.google.com/maps/embed/v1/view?key=${CONFIG.googleMapsApiKey}&center=${facility.latitude},${facility.longitude}&zoom=10`}
                  />
                </div>
              )}
            </div>
            <div className="col-md-6 offset-md-1">
              <div className="flex flex-column gap-48 md:gap-64">
                {pickUpTransportation && (
                  <div>
                    <h5 className="font-18 md:font-24 lh-title mb4 mb6-md">
                      {pickUpTransportation.title}
                    </h5>
                    <p>{pickUpTransportation.description}</p>
                    {deliveryProcessDescription && (
                      <p className="secondary i mt4">
                        &#8220;{deliveryProcessDescription}&#8221;
                      </p>
                    )}
                  </div>
                )}
                {dropOffTransportation && (
                  <div>
                    <h5 className="font-18 md:font-24 lh-title mb4 mb6-md">
                      {dropOffTransportation.title}
                    </h5>
                    <p className="mb4">{dropOffTransportation.description}</p>

                    {methods.map((method, idx) => {
                      const isMeetHalfway =
                        method.delivery_method.key === 'meet-halfway';
                      const title = isMeetHalfway
                        ? meetHalfway.description
                        : method.delivery_method.description;
                      const shortDescription = isMeetHalfway
                        ? meetHalfway.short_description
                        : method.delivery_method.short_description;

                      return (
                        <Drawer key={idx}>
                          <DrawerItem
                            itemKey={method.delivery_method.key}
                            className={classnames('pv4 pv6-md', {
                              'bt b--default': idx > 0
                            })}
                          >
                            <DrawerItemTitle
                              className="fw-medium"
                              iconClassName="secondary"
                              iconOpenName="fetch-chevron-up"
                              iconCloseName="fetch-chevron-down"
                            >
                              {title}
                            </DrawerItemTitle>
                            <DrawerItemContent className="pt2">
                              <p>{shortDescription}</p>
                              {method.description && (
                                <p className="secondary i mt4">
                                  &#8220;{method.description}&#8221;
                                </p>
                              )}
                              {method.cost_description && (
                                <p className="mt2">
                                  About the cost:{' '}
                                  <span className="secondary i">
                                    &#8220;{method.cost_description}&#8221;
                                  </span>
                                </p>
                              )}
                            </DrawerItemContent>
                          </DrawerItem>
                        </Drawer>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
