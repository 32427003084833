import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'validate.js';

import ClampedMultiLineText from 'dpl/common/components/ClampedMultiLineText';
import Icon from 'dpl/common/components/Icon';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { moreAboutBreederResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import { isLessThanBreakpoint } from 'dpl/util/grid';
import BreederProfileMoreAboutBreederItemsSkeleton from 'dpl/components/BreederProfile/Show/skeletons/BreederProfileMoreAboutBreederItemsSkeleton';

const IS_LT_MD = isLessThanBreakpoint('md');
const CLAMPED_TEXT_LINE_HEIGHT = IS_LT_MD ? 21.25 : 24;

export default function BreederProfileMoreAboutBreederItemsContainer() {
  const { breederProfileId } = useSelector(({ server }) => server);

  const {
    isFetching: isFetchingDetails,
    value: { data: details }
  } = useResourceQuery(moreAboutBreederResourceDefinition(breederProfileId));

  if (isFetchingDetails) {
    return <BreederProfileMoreAboutBreederItemsSkeleton />;
  }

  if (!isFetchingDetails && isEmpty(details)) {
    return null;
  }

  return (
    <div className="BreederProfileMoreAboutBreederItemsContainer">
      <ul className="flex flex-column gap-40 font-16 md:font-18">
        {details.map(detail => (
          <li key={detail.id}>
            <div className="flex gap-16">
              <Icon
                className="secondary"
                name={detail.icon_name}
                height="32px"
                width="32px"
              />
              <div>
                <p className="fw-medium mb1">{detail.title}</p>
                {detail.description && (
                  <ClampedMultiLineText
                    className="secondary"
                    text={detail.description}
                    numOfLines={4}
                    lineHeight={CLAMPED_TEXT_LINE_HEIGHT}
                    moreText="Read more"
                    lessText="Show less"
                    fadeToButton
                  />
                )}
                {Array.isArray(detail.items) && (
                  <ul className="flex flex-column gap-4">
                    {detail.items.map(({ type, url, label }) => {
                      // We're only expecting social links here
                      if (type !== 'link') return null;

                      return (
                        <li key={url}>
                          <a
                            href={url}
                            className="stone-700 underline"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {label}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
