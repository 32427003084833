import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'validate.js';

import BreederProfileLitterCarousel from 'dpl/components/BreederProfile/Show/BreederProfileLitterCarousel';
import BreederProfileLitterList from 'dpl/components/BreederProfile/Show/BreederProfileLitterList';
import BreederProfileCurrentLittersSectionSkeleton from 'dpl/components/BreederProfile/Show/skeletons/BreederProfileCurrentLittersSectionSkeleton';
import { LITTER_DISPLAY_STATES } from 'dpl/shared/constants/shared';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { nameUnnamedPuppies } from 'dpl/shared/utils/breeder_app';
import { littersResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import { isLessThanBreakpoint } from 'dpl/util/grid';

const IS_LT_MD = isLessThanBreakpoint('md');

export default function BreederProfileCurrentLittersSectionContainer() {
  const { breederProfileId } = useSelector(({ server }) => server);

  const {
    isFetching,
    value: { data: litters }
  } = useResourceQuery(
    littersResourceDefinition(breederProfileId, {
      status: LITTER_DISPLAY_STATES.CURRENT,
      exclude_adult: true
    })
  );

  const hasPuppies = useMemo(
    () => litters.some(litter => !isEmpty(litter.puppies)),
    [litters]
  );

  if (isFetching) {
    return <BreederProfileCurrentLittersSectionSkeleton />;
  }

  if (!hasPuppies) {
    return null;
  }

  const Component = IS_LT_MD
    ? BreederProfileLitterList
    : BreederProfileLitterCarousel;

  return (
    <div className="BreederProfileCurrentLittersSectionContainer flex flex-column gap-48">
      {litters.map(litter => (
        <Component
          key={litter.id}
          breederProfileId={breederProfileId}
          litterName={litter.parent_names}
          puppies={nameUnnamedPuppies(litter.puppies)}
          description={litter.age_with_ready_date_description}
        />
      ))}
    </div>
  );
}
