import React from 'react';
import { useSelector } from 'react-redux';

import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { adoptionProcessResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import { isLessThanBreakpoint } from 'dpl/util/grid';
import { ON_PLATFORM_POLICY_PATH } from 'dpl/shared/constants/urls';
import ClampedMultiLineText from 'dpl/common/components/ClampedMultiLineText';
import Banner from 'dpl/common/design_system/Banner';
import BreederProfileAdoptionProcessSkeleton from 'dpl/components/BreederProfile/Show/skeletons/BreederProfileAdoptionProcessSkeleton';
import SmartLink from 'dpl/components/SmartLink';
import SectionViewTracker from 'dpl/common/components/SectionViewTracker';

const IS_LT_MD = isLessThanBreakpoint('md');
const CLAMPED_TEXT_LINE_HEIGHT = IS_LT_MD ? 22.4 : 25.2;

export default function BreederProfileAdoptionProcessSectionContainer() {
  const { breederFirstName, breederProfileId, analyticsPageName } = useSelector(
    ({ server }) => server
  );

  const {
    isFetching,
    value: { data: adoptionProcess }
  } = useResourceQuery(adoptionProcessResourceDefinition(breederProfileId));

  if (isFetching) {
    return <BreederProfileAdoptionProcessSkeleton />;
  }

  return (
    <SectionViewTracker
      name={analyticsPageName}
      category="breeder-profile-about-program"
    >
      <section className="BreederProfileAdoptionProcessSection bg-subtle-light-gray pv12 pv20-md font-16 md:font-18">
        <div className="container-xxl">
          <div className="row wide-gutters">
            <div className="col-md-5">
              <h2 className="mb4 mb6-md font-24 md:font-40 lh-title">
                Getting a puppy from {breederFirstName}
              </h2>
              <p className="secondary lh-body mb10">
                {breederFirstName} has been certified by Good Dog&apos;s
                screening team for responsible and trusted breeding practices.
                When you&apos;re ready to reach out, feel free to ask any
                questions about the breed, their program, or specific puppies.
                You&apos;ll also have the chance to share a bit about yourself
                and what you&apos;re looking for in a new companion. Together,
                you&apos;ll choose the puppy that&apos;s right for you, stay in
                touch with regular updates, and plan how to bring your new puppy
                home.
              </p>
            </div>
            <div className="col-md-6 offset-md-1">
              <div className="pb6">
                <h5 className="font-18 md:font-24 mb1 mb4-md">Price</h5>
                <p className="secondary mb4">
                  {adoptionProcess.formatted_price_deposit_description}
                </p>
                {adoptionProcess.deposit_and_price_description && (
                  <p className="secondary i">
                    &#8220;{adoptionProcess.deposit_and_price_description}
                    &#8221;
                  </p>
                )}
              </div>
              <div className="pt6 bt b--gray-300">
                <h5 className="font-18 md:font-24 lh-title mb1 mb4-md">
                  Contract and health guarantee
                </h5>
                <ClampedMultiLineText
                  className="secondary lh-body"
                  text={`${breederFirstName} may provide a written contract or 
                  health guarantee when you purchase a puppy. This helps 
                  protect both you and your breeder, ensuring that you 
                  both have a clear understanding of the terms of your 
                  puppy purchase. If ${breederFirstName} offers a contract 
                  or guarantee, the details will be personalized by them.  
                  If you have any questions or want to know more, 
                  don’t hesitate to reach out to ${breederFirstName} directly.`}
                  numOfLines={3}
                  lineHeight={CLAMPED_TEXT_LINE_HEIGHT}
                  moreText="Read more"
                  lessText="Show less"
                  moreButtonClassName="stone-700"
                  fadeToBackgroundColor="#FAFAFA"
                  fadeToButton
                />
                <Banner
                  className="items-center mt8 mt6-md"
                  color="white"
                  iconVariant="dark"
                  iconName="fetch-payment-secure"
                >
                  Always communicate and pay on Good Dog.
                  <SmartLink
                    className="stone-700 underline ml2"
                    to={ON_PLATFORM_POLICY_PATH}
                    target="_blank"
                  >
                    Learn more
                  </SmartLink>
                </Banner>
              </div>
            </div>
          </div>
        </div>
      </section>
    </SectionViewTracker>
  );
}
