import { addToQuery, parseQueryString } from 'dpl/util/queryString';
import { BREED_ID, LITTER_ID, PUPPY_ID } from 'dpl/constants/query_params';

const REDIRECT_HASH_FRAGMENTS = [BREED_ID, LITTER_ID, PUPPY_ID];

// HACK: redirect if any of the REDIRECT_HASH_FRAGMENTS
// is specified as a query fragment rather than hash fragment,
// we should redirect and swap to a hash fragment
// while preserving all other parameters
export function redirectFromQueryToHash() {
  let { hash, search } = window.location;
  const hashFragments = parseQueryString(hash);
  const searchFragments = parseQueryString(search);

  let hasFragmentMismatch = false;
  Object.entries(searchFragments).forEach(([key, value]) => {
    if (REDIRECT_HASH_FRAGMENTS.includes(key)) {
      hashFragments[key] = value;
      delete searchFragments[key];

      hasFragmentMismatch = true;
    }
  });

  if (!hasFragmentMismatch) {
    return false;
  }

  search = addToQuery(searchFragments);
  hash = addToQuery(hashFragments);

  window.location.href = [
    window.location.href.split(/[?#]/)[0],
    ...(search ? [`?${search}`] : []),
    ...(hash ? [`#${hash}`] : [])
  ].join('');

  return true;
}

export function redirectMalformedHash() {
  let hasMalformedHash = false;
  const hashFragments = parseQueryString(window.location.hash);
  REDIRECT_HASH_FRAGMENTS.forEach(key => {
    if (key in hashFragments && /\D/.test(hashFragments[key])) {
      const correctVal = parseInt(hashFragments[key], 10);

      if (correctVal) {
        hashFragments[key] = correctVal;
      } else {
        delete hashFragments[key];
      }

      hasMalformedHash = true;
    }
  });

  if (hasMalformedHash) {
    window.location.hash = addToQuery(hashFragments);
    window.location.reload();
    return true;
  }

  return false;
}

export default function breederFragmentRedirect() {
  return redirectFromQueryToHash() || redirectMalformedHash();
}
