import React from 'react';
import { useSelector } from 'react-redux';

import BreederProfileCurrentLittersSectionContainer from '../BreederProfileCurrentLittersSectionContainer';
import BreederProfileLittersSectionContainer from '../BreederProfileLittersSectionContainer';
import BreederProfilePastPuppiesSectionContainer from '../BreederProfilePastPuppiesSectionContainer';

export default function BreederProfileLittersAndPuppiesTabContainer() {
  const { hasCurrentLitters, hasPastPuppies } = useSelector(
    ({ server }) => server
  );

  const pastPuppiesClassName = hasCurrentLitters ? 'nb12' : null;

  return (
    <div className="BreederProfileLittersAndPuppiesTabContainer flex flex-column gap-48">
      <BreederProfileCurrentLittersSectionContainer />
      {hasPastPuppies && (
        <BreederProfilePastPuppiesSectionContainer
          className={pastPuppiesClassName}
        />
      )}
      {hasCurrentLitters && (
        <BreederProfileLittersSectionContainer
          className="bg-white"
          mobileLayoutType="stacked"
        />
      )}
    </div>
  );
}
