import React from 'react';
import { useSelector } from 'react-redux';

import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import __DEPRECATED_defineResources from 'dpl/shared/fetching/deprecated/defineResources';
import { breederProfileBreedingProcessResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import { isLessThanBreakpoint } from 'dpl/util/grid';
import ClampedMultiLineText from 'dpl/common/components/ClampedMultiLineText';
import SmartImage from 'dpl/components/SmartImage';
import BreederProfileAboutPuppiesSkeleton from 'dpl/components/BreederProfile/Show/skeletons/BreederProfileAboutPuppiesSkeleton';

import dogWithBowl from 'dpl/assets/dog_with_bowl.png';

const IS_LT_MD = isLessThanBreakpoint('md');

export default function BreederProfileAboutPuppiesSectionContainer() {
  const { breederProfileId } = useSelector(({ server }) => server);

  const {
    isFetching,
    value: { data: breedingProcessData }
  } = useResourceQuery(
    breederProfileBreedingProcessResourceDefinition(breederProfileId)
  );

  if (isFetching) {
    return <BreederProfileAboutPuppiesSkeleton />;
  }

  const {
    extras_description: extrasDescription,
    health_process_description: healthDescription,
    training_description: trainingDescription
  } = breedingProcessData;

  const sections = [
    {
      name: 'enrichment-socialization',
      title: 'Enrichment and socialization',
      className: 'pb6',
      content: trainingDescription
    },
    {
      name: 'health-practices',
      title: 'Puppy health practices',
      className: 'pv6 bb bt b--gray-300',
      content: healthDescription
    },
    {
      name: 'extras',
      title: 'Extras',
      className: 'pt6',
      content: extrasDescription
    }
  ];

  return (
    <section className="BreederProfileAboutPuppiesSection bg-subtle-light-gray font-16 md:font-18">
      <div className="container-xxl">
        <div className="pv12 pv20-md bb bt b--gray-300">
          <div className="row wide-gutters">
            <div className="col-md-5">
              <h3 className="font-24 md:font-32 lh-title">
                What&apos;s included
              </h3>
              <SmartImage
                className="mt6 mb6 m12-md"
                src={dogWithBowl}
                width={IS_LT_MD ? '160px' : '240px'}
              />
            </div>
            <div className="col-md-6 offset-md-1">
              {sections.map(section => (
                <div key={section.name} className={section.className}>
                  <h5 className="font-18 md:font-24 lh-title mb1 mb4-md">
                    {section.title}
                  </h5>
                  <ClampedMultiLineText
                    className="secondary lh-body"
                    text={section.content}
                    numOfLines={3}
                    lineHeight={IS_LT_MD ? 22.4 : 25.2}
                    moreText="Read more"
                    lessText="Show less"
                    moreButtonClassName="stone-700"
                    fadeToBackgroundColor="#FAFAFA"
                    fadeToButton
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
