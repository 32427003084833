import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { BreederProfileBreedPropTypes } from 'dpl/shared/utils/customPropTypes';
import { conversationCreateMutationDefinition } from 'dpl/shared/messaging/utils/queryDefinitions';
import { CONSTRAINT_TYPES } from 'dpl/shared/validations/constraints';
import useResourceMutation from 'dpl/shared/fetching/hooks/useResourceMutation';
import useForm from 'dpl/shared/form/hooks/useForm';
import BreedAvailabilityList from 'dpl/adoption_application/components/BreedAvailabilityList';
import { sendRudderstackEvent } from 'dpl/util/analytics';
import { ANALYTICS_EVENTS, ANALYTICS_VIEWS } from 'dpl/constants/analytics';
import { CONVERSABLE_TYPES } from 'dpl/shared/messaging/constants';

const INPUT_NAME = 'message_text';

export default function BreederProfileQuestionModalMessageForm({
  breeds,
  onSubmit,
  breederProfileId
}) {
  const { mutateAsync: createConversation } = useResourceMutation(
    conversationCreateMutationDefinition
  );

  const {
    formState,
    handleSubmission,
    handleFieldChange,
    isDirty,
    isSubmitting,
    isValid
  } = useForm({
    mapFormStateToValidationSchema: () => ({
      message_text: CONSTRAINT_TYPES.TEXT
    }),
    onSubmit: async formStateForSubmission => {
      await createConversation({
        body: {
          source: 'ask_a_question_form',
          conversation: {
            with: {
              breeder_profile_id: breederProfileId
            },
            conversation_message: {
              message_text: formStateForSubmission.message_text
            }
          }
        }
      }).then(response => {
        const buyerProfile =
          response.data.conversation.conversation_members.filter(
            member => member.conversable.type === CONVERSABLE_TYPES.BUYER
          )[0];

        sendRudderstackEvent(ANALYTICS_EVENTS.MESSAGE_SENT_BY_USER, {
          view: 'ask_a_question_modal',
          view_feature: null,
          view_feature_location: null,
          origin_view: ANALYTICS_VIEWS.BREEDER_PROFILE_PAGE,
          origin_view_feature: 'ask_a_question_button',
          origin_view_feature_location: 'breeder_profile_header',
          sent_by: 'buyer',
          conversation_id: response.data.conversation.id,
          breeder_profile_id: breederProfileId,
          buyer_profile_id: buyerProfile.conversable.id,
          has_attachment: false,
          attachment_type: null
        });
        onSubmit();
      });
    }
  });

  const canSubmit = isDirty && isValid && formState[INPUT_NAME].trim();

  return (
    <form
      className="BreederProfileQuestionModalMessageForm"
      onSubmit={e => {
        e.preventDefault();
        handleSubmission();
      }}
    >
      <h5 className="mb6 tc">Ask a Question</h5>
      <textarea
        className="mb6 br2 ba b--light-gray w-100 input-reset pv2 ph2 h4 f3 lh-copy"
        name={INPUT_NAME}
        placeholder="Introduce yourself and what you're looking for along with your question."
        onChange={handleFieldChange}
      />
      <div className="mb12">
        <button
          disabled={!canSubmit || isSubmitting}
          type="submit"
          className={classnames('pv4 w-100 button button--primary', {
            'button--loading': isSubmitting,
            'button--disabled': !canSubmit
          })}
        >
          Send
        </button>
      </div>
      <div>
        <p className="mb4 f4 fw-medium">Availability</p>
        <BreedAvailabilityList breeds={breeds} />
      </div>
    </form>
  );
}

BreederProfileQuestionModalMessageForm.propTypes = {
  breeds: PropTypes.arrayOf(PropTypes.shape(BreederProfileBreedPropTypes))
    .isRequired,
  onSubmit: PropTypes.func.isRequired,
  breederProfileId: PropTypes.number.isRequired
};
