import React from 'react';

import SkeletonBlock from 'dpl/common/design_system/SkeletonBlock';
import { makeSequence } from 'dpl/shared/utils';

export default function BreederProfilePhotosSectionSkeleton() {
  return (
    <section className="BreederProfilePhotosSectionSkeleton bg-subtle-light-gray pv12 pv20-md">
      <div className="container-xxl">
        <SkeletonBlock
          className="font-24 md:font-40 mb6 mb8-md"
          height="1em"
          width="6em"
        />
        <ul className="grid md:grid-cols-2 gap-x-20 gap-y-40">
          {makeSequence(2).map(index => (
            <li key={index}>
              <div className="aspect-ratio aspect-ratio--8x5 relative">
                <div className="aspect-ratio--object">
                  <SkeletonBlock className="br3 h-100" />
                </div>
              </div>
              <div className="mt3 mt4-md">
                <SkeletonBlock
                  className="font-18 md:font-20 mb1"
                  height="1em"
                  width="6em"
                />
                <SkeletonBlock className="font-16" height="1em" width="4em" />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
