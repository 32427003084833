import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import SkeletonBlock from 'dpl/common/design_system/SkeletonBlock';

export default function MediaGridSkeleton({ className }) {
  return (
    <div className={classnames('MediaGridSkeleton', className)}>
      <SkeletonBlock className="MediaGridSkeleton__profile" />
      <div className="MediaGridSkeleton__gallery">
        <SkeletonBlock />
        <SkeletonBlock />
        <SkeletonBlock />
      </div>
    </div>
  );
}

MediaGridSkeleton.propTypes = {
  className: PropTypes.string
};

MediaGridSkeleton.defaultProps = {
  className: null
};
