import React from 'react';
import { useSelector } from 'react-redux';

import MediaGrid from 'dpl/components/MediaGrid';
import { BREED_ID } from 'dpl/constants/query_params';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { photographsResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import { isLessThanBreakpoint } from 'dpl/util/grid';

import BreederProfileDetailsCardContainer from '../BreederProfileDetailsCardContainer';
import BreederProfileIntroSectionContainer from '../BreederProfileIntroSectionContainer';

const IS_LT_LG = isLessThanBreakpoint('lg');

export default function BreederProfileMainContainer() {
  const { breederProfileId, breedId } = useSelector(
    ({ server, queryParams }) => ({
      breederProfileId: server.breederProfileId,
      breedId: queryParams[BREED_ID]
    })
  );

  const {
    value: { data: gallery },
    isFetching: isFetchingGallery
  } = useResourceQuery(
    photographsResourceDefinition(breederProfileId, {
      [BREED_ID]: breedId
    })
  );

  return (
    <div className="BreederProfileMainContainer container-xxl pb12 pb20-md mb4-md">
      <div className="row slim-gutters">
        <div className="col-12 col-lg-8">
          <div className="flex flex-column gap-40 md:gap-56">
            <MediaGrid
              isLoading={isFetchingGallery}
              gallery={gallery}
              carouselType="program"
            />
            {IS_LT_LG && <BreederProfileDetailsCardContainer />}
            <BreederProfileIntroSectionContainer className="pr20-xl mr20-xl" />
          </div>
        </div>
        {!IS_LT_LG && (
          <div className="col-12 col-lg-4">
            <BreederProfileDetailsCardContainer className="sticky top-2" />
          </div>
        )}
      </div>
    </div>
  );
}
