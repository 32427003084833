import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'validate.js';

import BreederProfilePhotosSectionSkeleton from 'dpl/components/BreederProfile/Show/skeletons/BreederProfilePhotosSectionSkeleton';
import SmartImage from 'dpl/components/SmartImage';
import { createBreederProfilePaginationAction } from 'dpl/containers/BreederProfile/Show/BreederProfilePhotoAlbumContainer';
import { PHOTO_ALBUM_TYPES } from 'dpl/shared/constants/breeder_app';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { groupBy } from 'dpl/shared/utils';
import { photographsResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';

const PREVIEW_CATEGORIES = [
  PHOTO_ALBUM_TYPES.BREEDER,
  PHOTO_ALBUM_TYPES.BREEDER_BREED
];

function isBreederProfilePreviewCategory(category) {
  return (
    PREVIEW_CATEGORIES.includes(category.album_type) && category.show_on_profile
  );
}

function getPreviewCategories(categories) {
  return categories
    .filter(isBreederProfilePreviewCategory)
    .map(cat => cat.name);
}

export default function BreederProfilePhotosSectionContainer() {
  const dispatch = useDispatch();

  const { breedId, breederProfileId, previewCategories } = useSelector(
    ({ server, queryParams }) => ({
      breedId: queryParams.breed_id,
      breederProfileId: server.breederProfileId,
      previewCategories: getPreviewCategories(server.mediaCategories.data)
    })
  );

  const {
    isLoading,
    value: { data: photographs }
  } = useResourceQuery(
    photographsResourceDefinition(breederProfileId, {
      breed_id: breedId
    })
  );

  const thumbnails = useMemo(() => {
    const categoryPhotos = groupBy(photographs, 'category');

    return previewCategories
      .map(category => {
        const photos = categoryPhotos[category] || [];

        if (isEmpty(photos)) return null;

        return {
          category,
          photo: photos[0],
          count: photos.length
        };
      })
      .filter(Boolean);
  }, [photographs]);

  function handlePhotoClick(photo) {
    dispatch(createBreederProfilePaginationAction(photo.id));
  }

  if (isLoading) {
    return <BreederProfilePhotosSectionSkeleton />;
  }

  if (isEmpty(thumbnails)) {
    return null;
  }

  return (
    <section className="BreederProfilePhotosSectionContainer bg-subtle-light-gray pv12 pv20-md">
      <div className="container-xxl">
        <h2 className="font-24 md:font-40 lh-title mb6 mb8-md">Photos</h2>
        <ul className="grid md:grid-cols-2 gap-x-20 gap-y-40">
          {thumbnails.map(({ photo, count, category }) => (
            <li key={photo.id}>
              <button
                type="button"
                className="w-100"
                onClick={() => handlePhotoClick(photo)}
              >
                <div className="aspect-ratio aspect-ratio--8x5 relative">
                  <div className="aspect-ratio--object">
                    <SmartImage
                      Tag="div"
                      className="w-100 h-100 cover bg-center"
                      wrapperOnlyClassName="br3 overflow-hidden"
                      src={photo.url_three_two_gallery_2x}
                    />
                  </div>
                </div>
                <div className="mt3 mt4-md">
                  <p className="font-18 md:font-20 lh-title md:font-serif fw-medium mb1">
                    {category}
                  </p>
                  <p className="font-16 secondary">
                    {count} {count === 1 ? 'photo' : 'photos'}
                  </p>
                </div>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
