import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { isLessThanBreakpoint } from 'dpl/util/grid';
import useParentModal from 'dpl/dog_modals/parent_modal/hooks/useParentModal';

import Icon from './Icon';
import TooltipWrapper from '../tooltip/components/TooltipWrapper';

const IS_LT_MD = isLessThanBreakpoint('md');

const HEALTH_BADGE_SIZE = IS_LT_MD ? '24px' : '32px';
const MAX_HEALTH_TESTS_TO_SHOW = 2;

function getParentHealthTestingDescription(healthTests) {
  const remainingTestCount = healthTests.length - MAX_HEALTH_TESTS_TO_SHOW;
  const healthTestsToDisplay = healthTests
    .slice(0, MAX_HEALTH_TESTS_TO_SHOW)
    .join(', ');
  return `${healthTestsToDisplay}${
    remainingTestCount > 0 ? `, +${remainingTestCount} more` : ''
  }`;
}

// used on the puppy page and breeder profile page
export default function ParentHealthTestingBanner({
  className,
  healthTesting,
  parentId
}) {
  const { onDogClick: openParentModal } = useParentModal();

  const hasHealthTests = healthTesting.documented_health_tests?.length > 0;

  return (
    <div className={classnames('ba b--light-gray br3 ph4 pv4', className)}>
      <div className="flex flex-column flex-md-row">
        <div className="self-start bg-stroll-light br-100 pv1 ph1 mr2 mr4-lg mb2 mb0-md">
          <Icon
            name="fetch-pets-heart"
            className="stroll-dark"
            width={HEALTH_BADGE_SIZE}
            height={HEALTH_BADGE_SIZE}
          />
        </div>
        <div
          className={classnames(
            'flex flex-column flex-lg-row flex-auto justify-between-ns',
            { 'items-center-l': !hasHealthTests }
          )}
        >
          <div>
            <div className="flex items-center">
              <p className="fw-medium stroll-dark mr2">{healthTesting.title}</p>
              <TooltipWrapper
                minWidth="220px"
                title={
                  <p>
                    Health testing is one key piece of responsible breeding and
                    is performed on breeding dogs to prevent the presence of
                    heritable conditions in their puppies.
                  </p>
                }
              >
                <Icon
                  className="stone-700"
                  name="fetch-info"
                  width="20px"
                  height="20px"
                />
              </TooltipWrapper>
            </div>
            {hasHealthTests && (
              <p className="secondary">
                {getParentHealthTestingDescription(
                  healthTesting.documented_health_tests
                )}
              </p>
            )}
          </div>
          <button
            type="button"
            onClick={() => openParentModal(parentId)}
            className={classnames('nowrap mt2 mt0-lg stone-700 underline', {
              'self-start': hasHealthTests
            })}
          >
            View details
          </button>
        </div>
      </div>
    </div>
  );
}

ParentHealthTestingBanner.propTypes = {
  className: PropTypes.string,
  healthTesting: PropTypes.shape({
    title: PropTypes.string,
    documented_health_tests: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  parentId: PropTypes.number.isRequired
};

ParentHealthTestingBanner.defaultProps = {
  className: ''
};
