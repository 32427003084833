import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SkeletonBlock from 'dpl/common/design_system/SkeletonBlock';
import { makeSequence } from 'dpl/shared/utils';

export default function BreederProfileLittersSkeleton({ className }) {
  return (
    <div
      className={classnames('BreederProfileLittersSkeleton font-16', className)}
    >
      <div className="container-xxl">
        <div className="pv12 pv20-md bb b--gray-300">
          <SkeletonBlock
            className="mb4 mb10-md font-24 md:font-32"
            height="1em"
            width="12em"
          />
          <div className="db dn-ns">
            <SkeletonBlock height="250px" width="100%" />
          </div>
          <div className="dn flex-ns flex-column gap-8">
            {makeSequence(3).map(index => (
              <SkeletonBlock key={index} height="6em" width="100%" />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

BreederProfileLittersSkeleton.propTypes = {
  className: PropTypes.string
};

BreederProfileLittersSkeleton.defaultProps = {
  className: 'bg-subtle-light-gray'
};
