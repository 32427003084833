import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import ClampedMultiLineText from 'dpl/common/components/ClampedMultiLineText';
import Icon from 'dpl/common/components/Icon';
import { BUTTON_SIZES, BUTTON_VARIANTS } from 'dpl/common/design_system/Button';
import BreederProfileQuestionButton from 'dpl/components/BreederProfile/Show/BreederProfileQuestionButton';
import BreederProfileIntroSectionSkeleton from 'dpl/components/BreederProfile/Show/skeletons/BreederProfileIntroSectionSkeleton';
import SmartImage from 'dpl/components/SmartImage';
import {
  ANALYTICS_VIEW_FEATURE_LOCATIONS,
  ANALYTICS_VIEW_FEATURES,
  ANALYTICS_VIEWS
} from 'dpl/constants/analytics';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import {
  breederInterviewResourceDefinition,
  singleBreederProfileResourceDefinition
} from 'dpl/shared/utils/tptConnectResourceDefinitions';
import { isLessThanBreakpoint } from 'dpl/util/grid';

const IS_LT_MD = isLessThanBreakpoint('md');
const CLAMPED_TEXT_LINE_HEIGHT = IS_LT_MD ? 21.25 : 24;

const QUESTION_KEYS = {
  ABOUT_BREEDER: 'about-breeder',
  ABOUT_PROGRAM: 'about-program'
};

function getBreederExperienceItems({
  firstName,
  answers,
  certificationNames,
  clubNames,
  clubCount
}) {
  const aboutBreeder = answers.find(
    answer => answer.question_key === QUESTION_KEYS.ABOUT_BREEDER
  );

  const aboutProgram = answers.find(
    answer => answer.question_key === QUESTION_KEYS.ABOUT_PROGRAM
  );

  return [
    aboutBreeder && {
      heading: `About ${firstName}`,
      description: aboutBreeder.answer,
      iconName: 'fetch-pets-heart'
    },
    aboutProgram && {
      heading: `What makes ${firstName}’s program special`,
      description: aboutProgram.answer,
      iconName: 'fetch-favorite-date'
    },
    clubCount > 0 && {
      heading: `${firstName} is a member of ${
        clubCount === 1 ? 'a club' : [clubCount, 'clubs'].join(' ')
      }`,
      description: clubNames,
      iconName: 'fetch-animal-badge'
    },
    certificationNames && {
      heading: `${firstName}’s certifications & recognitions`,
      description: certificationNames,
      iconName: 'fetch-animal-cup'
    }
  ].filter(Boolean);
}

export default function BreederProfileIntroSectionContainer({ className }) {
  const { breederProfileId, introParagraph } = useSelector(
    ({ server }) => server
  );

  const { value: breeder, isFetching: isFetchingBreeder } = useResourceQuery(
    singleBreederProfileResourceDefinition(breederProfileId)
  );

  const {
    value: { data: interview },
    isFetching: isFetchingInterview
  } = useResourceQuery(breederInterviewResourceDefinition(breederProfileId));

  if (isFetchingBreeder || isFetchingInterview) {
    return <BreederProfileIntroSectionSkeleton className={className} />;
  }

  const experienceItems = getBreederExperienceItems({
    firstName: breeder.user.first_name,
    answers: interview.answers,
    certificationNames: interview.formatted_certifications,
    clubNames: interview.formatted_clubs,
    clubCount: interview.club_count
  });

  return (
    <div
      className={classnames('BreederProfileIntroSectionContainer', className)}
    >
      <h2 className="font-28 md:font-40 lh-title mb5 mb6-md">
        Meet the breeder
      </h2>
      <ClampedMultiLineText
        className="font-16 md:font-18 secondary mb8 mb10-md"
        text={introParagraph}
        numOfLines={6}
        lineHeight={CLAMPED_TEXT_LINE_HEIGHT}
        moreText="Read more"
        lessText="Show less"
        fadeToButton
      />
      {IS_LT_MD && (
        <BreederProfileQuestionButton
          className={classnames(
            BUTTON_SIZES.default,
            BUTTON_VARIANTS.outline,
            'font-16 w-100 nt2 mb8'
          )}
          analytics={{
            view: ANALYTICS_VIEWS.BREEDER_PROFILE,
            view_feature: ANALYTICS_VIEW_FEATURES.ASK_A_QUESTION_BUTTON,
            view_feature_location:
              ANALYTICS_VIEW_FEATURE_LOCATIONS.BREEDER_PROFILE_OVERVIEW
          }}
        />
      )}
      {interview.photo?.url && (
        <div className="aspect-ratio aspect-ratio--6x4 relative mb10 mb13-md">
          <div className="aspect-ratio--object">
            <SmartImage
              Tag="div"
              className="w-100 h-100 cover bg-center"
              wrapperOnlyClassName="br3 overflow-hidden"
              src={interview.photo.url}
            />
          </div>
        </div>
      )}
      {experienceItems.length > 0 && (
        <div>
          <h3 className="font-20 md:font-24 lh-title mb4 mb6-md">
            {breeder.user.first_name}’s experience
          </h3>
          <ul className="flex flex-column gap-24 md:gap-32 font-16 md:font-18">
            {experienceItems.map(item => (
              <li key={item.heading}>
                <div className="flex flex-column flex-md-row gap-8 md:gap-16">
                  <Icon
                    className="secondary"
                    name={item.iconName}
                    height="32px"
                    width="32px"
                  />
                  <div>
                    <p className="fw-medium mb1">{item.heading}</p>
                    <ClampedMultiLineText
                      className="secondary"
                      text={item.description}
                      numOfLines={4}
                      lineHeight={CLAMPED_TEXT_LINE_HEIGHT}
                      moreText="Read more"
                      lessText="Show less"
                      fadeToButton
                    />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

BreederProfileIntroSectionContainer.propTypes = {
  className: PropTypes.string
};

BreederProfileIntroSectionContainer.defaultProps = {
  className: null
};
