import { useDispatch, useSelector } from 'react-redux';

import { pushParams } from 'dpl/actions/query_params';

import {
  TAB_KEYS,
  TAB_NAMES,
  TAB_VIEW_FEATURE_LOCATIONS
} from '../utils/constants';

export default function useBreederProfileTabs() {
  const dispatch = useDispatch();

  const {
    testimonialsCount,
    hasBreederPosts,
    hasPastPuppies,
    hasCurrentLitters
  } = useSelector(({ server }) => server);

  const enabledTabKeyValues = Object.values(TAB_KEYS).filter(tabKey => {
    switch (tabKey) {
      case TAB_KEYS.DOGS: {
        return hasCurrentLitters || hasPastPuppies;
      }
      case TAB_KEYS.UPDATES: {
        return hasBreederPosts;
      }
      case TAB_KEYS.STORIES: {
        return testimonialsCount > 0;
      }
      default: {
        return true;
      }
    }
  });

  const enabledTabNameValues = Object.values(
    enabledTabKeyValues.map(tabKey => TAB_NAMES[tabKey])
  );

  const activeTabKey = useSelector(({ queryParams: { tab } }) =>
    enabledTabKeyValues.includes(tab) ? tab : TAB_KEYS.OVERVIEW
  );

  const activeTabIndex = enabledTabKeyValues.indexOf(activeTabKey);
  const viewFeatureLocation = TAB_VIEW_FEATURE_LOCATIONS[activeTabKey];

  function handleTabClick(tabIndex) {
    const tabKey = enabledTabKeyValues[tabIndex] || TAB_KEYS.OVERVIEW;

    dispatch(pushParams({ tab: tabKey }, { hash: true }));
  }

  return {
    activeTabIndex,
    activeTabKey,
    tabNames: enabledTabNameValues,
    tabKeys: enabledTabKeyValues,
    onTabClick: handleTabClick,
    viewFeatureLocation
  };
}
