import PropTypes from 'prop-types';
import React from 'react';

import ClickableDiv from 'dpl/common/components/ClickableDiv';
import Badge from 'dpl/common/design_system/Badge';
import ImagePlaceholder from 'dpl/components/MediaGrid/ImagePlaceholder';
import SmartImage from 'dpl/components/SmartImage';
import {
  ANALYTICS_EVENTS,
  ANALYTICS_VIEW_FEATURES,
  ANALYTICS_VIEWS
} from 'dpl/constants/analytics';
import { openPuppyPage } from 'dpl/puppy_page/utils/helpers';
import { capitalize } from 'dpl/shared/utils';
import { sendRudderstackEvent } from 'dpl/util/analytics';
import { isLessThanBreakpoint } from 'dpl/util/grid';

import useBreederProfileTabs from '../../hooks/useBreederProfileTabs';

const IMAGE_SIZE = isLessThanBreakpoint('lg') ? '335px' : '416px';

export default function BreederProfileLitterPuppyCard({
  className,
  puppy,
  breederProfileId
}) {
  const { viewFeatureLocation } = useBreederProfileTabs();

  const unavailable = puppy.reserved || puppy.snoozed;

  const description = [capitalize(puppy.gender || ''), puppy.status_with_date]
    .filter(Boolean)
    .join(' • ');

  return (
    <ClickableDiv
      className={className}
      onClick={() => {
        sendRudderstackEvent(ANALYTICS_EVENTS.CARD_CLICKED_BY_USER, {
          view: ANALYTICS_VIEWS.BREEDER_PROFILE,
          view_feature: ANALYTICS_VIEW_FEATURES.PUPPY_CARD,
          view_feature_location: viewFeatureLocation,
          dog_id: puppy.id,
          breeder_profile_id: breederProfileId,
          breed_id: puppy.breed_id
        });

        openPuppyPage({ puppyId: puppy.id });
      }}
      data-test-id={`puppy-${puppy.id}`}
    >
      <div className="BreederProfileLitterPuppyCard h-100 flex flex-column br3 ba b--light-gray overflow-hidden">
        {puppy.profile_photo_url ? (
          <SmartImage
            src={puppy.profile_photo_url}
            Tag="div"
            className="w-100 bg-center cover br3 br--top"
            loadingClass="bg-light-gray"
            height={IMAGE_SIZE}
            allowContextMenu
            crop
          />
        ) : (
          <div style={{ height: IMAGE_SIZE }}>
            <ImagePlaceholder className="bg-branch-light" />
          </div>
        )}
        <div className="pv4 ph4 bg-white">
          <div className="flex items-center justify-between">
            <p className="font-serif font-20 truncate">{puppy.name}</p>
            <Badge size="small" color={unavailable ? 'gray' : 'stroll'}>
              {unavailable ? 'Reserved' : 'Available'}
            </Badge>
          </div>

          <p className="font-16 secondary mv1">{description}</p>
        </div>
      </div>
    </ClickableDiv>
  );
}

export const PuppyPropTypes = PropTypes.shape({
  reserved: PropTypes.bool.isRequired,
  breed_id: PropTypes.number.isRequired,
  gender: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  profile_photo_url: PropTypes.string,
  snoozed: PropTypes.bool.isRequired,
  status_with_date: PropTypes.string
});

BreederProfileLitterPuppyCard.propTypes = {
  className: PropTypes.string,
  breederProfileId: PropTypes.string.isRequired,
  puppy: PuppyPropTypes.isRequired
};

BreederProfileLitterPuppyCard.defaultProps = {
  className: null
};
