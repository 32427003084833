import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { SIGNUP_FORM_TYPES } from 'dpl/shared/constants/shared';
import {
  BUYER_MESSAGES_PATH,
  BREEDER_MESSAGES_PATH
} from 'dpl/shared/constants/urls';
import { singleBreederProfileResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import { set } from 'dpl/shared/utils/object';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import useCurrentUser from 'dpl/common/hooks/useCurrentUser';
import LoadingWrapper from 'dpl/components/LoadingWrapper';
import MiniOverlayModal from 'dpl/common/mini_overlay_modal/components/MiniOverlayModal';
import ContactModalSuccess from 'dpl/components/ContactModal/ContactModalSuccess';
import BreederProfileQuestionModalLoginForm from 'dpl/components/BreederProfile/Show/BreederProfileQuestionModal/BreederProfileQuestionModalLoginForm';
import BreederProfileQuestionModalSignupForm from 'dpl/components/BreederProfile/Show/BreederProfileQuestionModal/BreederProfileQuestionModalSignupForm';
import BreederProfileQuestionModalMessageForm from 'dpl/components/BreederProfile/Show/BreederProfileQuestionModal/BreederProfileQuestionModalMessageForm';
import SmartImage from 'dpl/components/SmartImage';

export default function BreederProfileQuestionModal({
  breederProfileId,
  onClose
}) {
  const [authModalType, setAuthModalType] = useState(SIGNUP_FORM_TYPES.LOGIN);
  const [didSubmit, setDidSubmit] = useState(false);

  const resourceDefinition =
    singleBreederProfileResourceDefinition(breederProfileId);
  const breederProfileResource = useResourceQuery(
    set(resourceDefinition, 'params.approved_breeds', true)
  );
  const { value: breederProfileValue } = breederProfileResource;
  const currentUser = useCurrentUser();

  return (
    <MiniOverlayModal maxWidth="600px" onCloseClick={onClose}>
      <LoadingWrapper
        isLoading={currentUser.isFetching || breederProfileResource.isFetching}
      >
        {!didSubmit && (
          <div className="pv4 bb b--light-gray tc">
            <div className="mb2">
              <SmartImage
                className="br-100"
                crop
                height="56px"
                loadingClass="bg-light-gray"
                src={breederProfileValue.profile_photo_url}
                width="56px"
              />
            </div>
            <p className="f2">
              Contact {breederProfileValue.user.first_name} from{' '}
              {breederProfileValue.name}
            </p>
          </div>
        )}
        <div className="BreederProfileQuestionModal__contents">
          {!currentUser.isLoggedIn ? (
            <div className="pt10">
              <h5 className="mb2 tc">Ask a Question</h5>
              <p className="mb6 tc black-50">
                Please log in or sign up to continue.
              </p>
              {authModalType === SIGNUP_FORM_TYPES.LOGIN ? (
                <BreederProfileQuestionModalLoginForm
                  onAuth={currentUser.fetch}
                  onToggle={() => setAuthModalType(SIGNUP_FORM_TYPES.SIGNUP)}
                />
              ) : (
                <BreederProfileQuestionModalSignupForm
                  onAuth={currentUser.fetch}
                  onToggle={() => setAuthModalType(SIGNUP_FORM_TYPES.LOGIN)}
                />
              )}
            </div>
          ) : (
            <div className="ph10 ph18-lg pv10">
              {didSubmit ? (
                <ContactModalSuccess displayName={breederProfileValue.name}>
                  <a
                    className="button button--primary dib"
                    href={
                      currentUser.isBreeder
                        ? BREEDER_MESSAGES_PATH
                        : BUYER_MESSAGES_PATH
                    }
                  >
                    View Messages
                  </a>
                </ContactModalSuccess>
              ) : (
                <BreederProfileQuestionModalMessageForm
                  breederProfileId={breederProfileValue.id}
                  displayName={breederProfileValue.name}
                  onSubmit={() => setDidSubmit(true)}
                  breeds={breederProfileValue.breeds}
                />
              )}
            </div>
          )}
        </div>
      </LoadingWrapper>
    </MiniOverlayModal>
  );
}

BreederProfileQuestionModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  breederProfileId: PropTypes.number.isRequired
};
