import React from 'react';

import SkeletonBlock from 'dpl/common/design_system/SkeletonBlock';

export default function BreederProfileTrustedCardSkeleton() {
  return (
    <SkeletonBlock
      className="BreederProfileTrustedCardSkeleton font-16 md:font-20 br3"
      height="24em"
    />
  );
}
