import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'dpl/common/components/Icon';

export default function ImageCarouselImageCaption({ caption }) {
  return (
    <div className="ImageCarouselImageCaption pv2 ph2 flex items-center">
      <Icon className="mr2" name="tag" />
      {caption}
    </div>
  );
}

ImageCarouselImageCaption.propTypes = {
  caption: PropTypes.string.isRequired
};
