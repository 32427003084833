import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import SkeletonBlock from 'dpl/common/design_system/SkeletonBlock';
import { makeSequence } from 'dpl/shared/utils';
import { isLessThanBreakpoint } from 'dpl/util/grid';

const IS_LT_MD = isLessThanBreakpoint('md');

export default function BreederProfileIntroSectionSkeleton({ className }) {
  return (
    <div
      className={classnames('BreederProfileIntroSectionSkeleton', className)}
    >
      <SkeletonBlock
        className="font-28 md:font-40 mb5 mb7-md"
        height="1em"
        width="8em"
      />
      <SkeletonBlock
        className="font-16 md:font-18 mb8 mb10-md"
        height={IS_LT_MD ? '8em' : '5em'}
      />
      {IS_LT_MD && (
        <SkeletonBlock className="font-16 w-100 nt2 nb2" height="3em" />
      )}
      <SkeletonBlock className="w-100 br3 mb10 mb13-md" />
      <div className="aspect-ratio aspect-ratio--6x4 relative mb10 mb13-md">
        <SkeletonBlock className="aspect-ratio--object br3" />
      </div>
      <SkeletonBlock
        className="font-20 md:font-24 mb4 mb6-md"
        width="10em"
        height="1em"
      />
      <div className="flex flex-column gap-24 md:gap-32">
        {makeSequence(3).map(index => (
          <div
            key={index}
            className="flex flex-column flex-md-row gap-8 md:gap-16"
          >
            <div>
              <SkeletonBlock className="br-100" height="32px" width="32px" />
            </div>
            <SkeletonBlock height="6em" width="100%" />
          </div>
        ))}
      </div>
    </div>
  );
}

BreederProfileIntroSectionSkeleton.propTypes = {
  className: PropTypes.string
};

BreederProfileIntroSectionSkeleton.defaultProps = {
  className: null
};
