import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { BreederProfileBreedPropTypes } from 'dpl/shared/utils/customPropTypes';
import SmartImage from 'dpl/components/SmartImage';

import { trackBuyerApplicationQuestionView } from '../utils';

export default function BreedAvailabilityListItem({
  breed,
  breederSlug,
  programName
}) {
  useEffect(() => {
    if (breed.snoozed) {
      trackBuyerApplicationQuestionView('Availability & Pricing Snoozed', {
        programName,
        breederSlug
      });
    }
  }, []);

  return (
    <div className="BreedAvailabilityListItem flex items-center w-100">
      <span className="f0 mr4">
        <SmartImage
          Tag="div"
          puppy
          crop
          lazy
          className={classnames('bg-center cover br-100', {
            'o-50': breed.snoozed
          })}
          src={breed.cover_photo_url}
          width="76px"
          height="76px"
        />
      </span>
      <div className="flex-auto">
        <p
          className={classnames('truncate subheader mb1', {
            'o-50': breed.snoozed
          })}
        >
          {breed.name}
        </p>
        {breed.snoozed ? (
          <p className="red mb1">Applications closed</p>
        ) : (
          <p className="mb1">{breed.formatted_availability}</p>
        )}
        <p className={classnames({ 'o-50': breed.snoozed })}>
          {breed.formatted_price_range}
        </p>
      </div>
    </div>
  );
}

BreedAvailabilityListItem.propTypes = {
  breed: PropTypes.shape(BreederProfileBreedPropTypes).isRequired,
  breederSlug: PropTypes.string,
  programName: PropTypes.string
};

BreedAvailabilityListItem.defaultProps = {
  breederSlug: null,
  programName: null
};
