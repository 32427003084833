import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import Icon from 'dpl/common/components/Icon';
import { isLessThanBreakpoint } from 'dpl/util/grid';

import BreederPostCard from './BreederPostCard';
import { BreederPostCardPropTypes } from '../utils/propTypes';

const IS_LT_LG = isLessThanBreakpoint('lg');
const IS_LT_SM = isLessThanBreakpoint('sm');
let CARDS_PER_PAGE = 3;

if (IS_LT_SM) {
  CARDS_PER_PAGE = 1;
} else if (IS_LT_LG) {
  CARDS_PER_PAGE = 2;
}

export default function BreederPostCardsCarousel({
  analytics,
  breederPosts,
  className,
  refetchBreederPosts,
  showLitterTag
}) {
  const splideRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  if (breederPosts.length === 0) {
    return null;
  }

  const isNextButtonDisabled =
    activeIndex >= breederPosts.length - CARDS_PER_PAGE;
  const isPrevButtonDisabled = activeIndex === 0;

  function handleActiveSlide(slide) {
    setActiveIndex(slide.index);
  }

  return (
    <div className={classnames('BreederPostCardsCarousel', className)}>
      <Splide
        options={{
          arrows: false,
          drag: breederPosts.length > CARDS_PER_PAGE,
          dragMinThreshold: 20,
          flickMaxPages: CARDS_PER_PAGE,
          flickPower: 300,
          gap: 16,
          keyboard: 'focused',
          pagination: false,
          perPage: CARDS_PER_PAGE,
          snap: true,
          start: activeIndex
        }}
        ref={splideRef}
        onActive={handleActiveSlide}
      >
        {breederPosts.map((post, idx) => (
          <SplideSlide key={idx}>
            <BreederPostCard
              analytics={analytics}
              breederPost={post}
              refetchBreederPost={refetchBreederPosts}
              showLitterTag={showLitterTag}
            />
          </SplideSlide>
        ))}
      </Splide>
      {breederPosts.length > CARDS_PER_PAGE && (
        <div className="dn db-ns mt8 secondary">
          <button
            type="button"
            className={classnames('bg-white ba b--default br-100 mr4', {
              'o-60': isPrevButtonDisabled
            })}
            disabled={isPrevButtonDisabled}
            onClick={() => splideRef.current.go('<')}
          >
            <div className="flex items-center justify-center ph3 pv3">
              <Icon name="fetch-arrow-left" />
            </div>
          </button>
          <button
            type="button"
            className={classnames('bg-white ba b--default br-100', {
              'o-60': isNextButtonDisabled
            })}
            disabled={isNextButtonDisabled}
            onClick={() => splideRef.current.go('>')}
          >
            <div className="flex items-center justify-center ph3 pv3">
              <Icon name="fetch-arrow-right" />
            </div>
          </button>
        </div>
      )}
    </div>
  );
}

BreederPostCardsCarousel.propTypes = {
  analytics: PropTypes.shape({
    view: PropTypes.string,
    view_feature_location: PropTypes.string
  }),
  breederPosts: PropTypes.arrayOf(PropTypes.shape(BreederPostCardPropTypes))
    .isRequired,
  className: PropTypes.string,
  refetchBreederPosts: PropTypes.func.isRequired,
  showLitterTag: PropTypes.bool
};

BreederPostCardsCarousel.defaultProps = {
  analytics: {},
  className: null,
  showLitterTag: false
};
