import React from 'react';

import { isLessThanBreakpoint } from 'dpl/util/grid';
import SkeletonBlock from 'dpl/common/design_system/SkeletonBlock';

const IS_LT_MD = isLessThanBreakpoint('md');
const AVATAR_SIZE = IS_LT_MD ? '64px' : '72px';

export default function BreederProfileUpdatesTabSkeleton() {
  return (
    <div className="BreederProfileUpdatesTabSkeleton container-xxl">
      <div className="flex items-center">
        <SkeletonBlock
          className="br-100"
          height={AVATAR_SIZE}
          width={AVATAR_SIZE}
        />
        <div className="ml4">
          <SkeletonBlock
            className="font-20 md:font-24 lh-title"
            height="1em"
            width="6em"
          />
          <SkeletonBlock
            className="font-14 br-pill mt2"
            height="2em"
            width="9em"
          />
        </div>
      </div>
      <SkeletonBlock
        className="font-16 md:font-18 lh-body mt4 w-100"
        height="1em"
      />
      <div className="flex flex-column gap-16 md:gap-24 mv8 mv12-md w-100">
        <SkeletonBlock className="font-16" height="1em" width="4em" />
        {[300, 500, 400].map((height, idx) => (
          <SkeletonBlock key={idx} className="br12" height={`${height}px`} />
        ))}
      </div>
    </div>
  );
}
