import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import BreederProfileLittersAndPuppiesTabContainer from 'dpl/containers/BreederProfile/Show/BreederProfileLittersAndPuppiesTabContainer';
import BreederProfileOverviewTabContainer from 'dpl/containers/BreederProfile/Show/BreederProfileOverviewTabContainer';
import BreederProfileOwnerStoriesTabContainer from 'dpl/containers/BreederProfile/Show/BreederProfileOwnerStoriesTabContainer';
import BreederProfileUpdatesTabContainer from 'dpl/containers/BreederProfile/Show/BreederProfileUpdatesTabContainer';
import { trackPageView } from 'dpl/util/analytics';

import useBreederProfileTabs from '../../hooks/useBreederProfileTabs';
import { PAGE_CATEGORIES, TAB_KEYS } from '../../utils/constants';

const TAB_CONTENT = {
  [TAB_KEYS.OVERVIEW]: BreederProfileOverviewTabContainer,
  [TAB_KEYS.DOGS]: BreederProfileLittersAndPuppiesTabContainer,
  [TAB_KEYS.UPDATES]: BreederProfileUpdatesTabContainer,
  /* TODO: Uncomment when ready */
  // [TAB_KEYS.PROGRAM]: BreederProfileProgramTabContainer,
  [TAB_KEYS.STORIES]: BreederProfileOwnerStoriesTabContainer
};

export default function BreederProfileTabContent() {
  const { activeTabKey } = useBreederProfileTabs();
  const { analyticsPageName } = useSelector(({ server }) => server);

  const TabContent = TAB_CONTENT[activeTabKey];

  useEffect(() => {
    trackPageView({
      name: analyticsPageName,
      category: PAGE_CATEGORIES[activeTabKey]
    });
  }, [activeTabKey]);

  return <TabContent />;
}
