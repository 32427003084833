import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import PreferredBreederBadge from 'dpl/breeder_preferred_qualifications/components/PreferredBreederBadge';
import { STATUS } from 'dpl/breeder_preferred_qualifications/utils/constants';
import Icon from 'dpl/common/components/Icon';
import Button, { BUTTON_VARIANTS } from 'dpl/common/design_system/Button';
import BreederProfileQuestionButton from 'dpl/components/BreederProfile/Show/BreederProfileQuestionButton';
import BreederProfileDetailsCardSkeleton from 'dpl/components/BreederProfile/Show/skeletons/BreederProfileDetailsCardSkeleton';
import {
  ANALYTICS_VIEW_FEATURE_LOCATIONS,
  ANALYTICS_VIEW_FEATURES,
  ANALYTICS_VIEWS
} from 'dpl/constants/analytics';
import { SECTION_ID as PUPPIES_SECTION_ID } from 'dpl/containers/BreederProfile/Show/BreederProfileCurrentPuppiesSectionContainer';
import UserAvatar from 'dpl/path_to_payment/components/UserAvatar';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { singleBreederProfileResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import { isLessThanBreakpoint } from 'dpl/util/grid';

import BreederProfileApplyButtonContainer from '../BreederProfileApplyButtonContainer';
import { SECTION_ID as STORIES_SECTION_ID } from '../BreederProfileOwnerStoriesContainer';

const IS_LT_MD = isLessThanBreakpoint('md');

const COMMON_ANALYTICS_PROPS = {
  view: ANALYTICS_VIEWS.BREEDER_PROFILE,
  view_feature_location:
    ANALYTICS_VIEW_FEATURE_LOCATIONS.BREEDER_PROFILE_OVERVIEW
};

function scrollToSection(sectionId) {
  const section = document.getElementById(sectionId);
  if (section) section.scrollIntoView({ behavior: 'smooth' });
}

export default function BreederProfileDetailsCardContainer({ className }) {
  const {
    breederProfileId,
    testimonialsCount,
    breederPreferredStatus,
    availablePuppyCount
  } = useSelector(({ server }) => server);

  const { value: breeder, isFetching: isFetchingBreeder } = useResourceQuery(
    singleBreederProfileResourceDefinition(breederProfileId)
  );

  const isPreferredBreeder = breederPreferredStatus === STATUS.PREFERRED;

  const breederResponseDescription = [
    breeder.response_rate_description,
    breeder.response_time_description
  ]
    .filter(Boolean)
    .join(' • ');

  function getBreederDescription() {
    const hasClubMembership = breeder.club_count > 0;

    const clubMembershipDescription = hasClubMembership
      ? `${breeder.club_count} club ${
          breeder.club_count > 1 ? 'memberships' : 'membership'
        }`
      : '';

    const description = [
      clubMembershipDescription,
      isPreferredBreeder && 'Preferred Breeder'
    ]
      .filter(Boolean)
      .join(' • ');

    if (!description) return 'Certified Good Dog Breeder';

    return description;
  }

  if (isFetchingBreeder) {
    return <BreederProfileDetailsCardSkeleton />;
  }

  return (
    <div
      className={classnames(
        'BreederProfileDetailsCardContainer flex flex-column justify-between br4 ba b--default overflow-hidden',
        className
      )}
      style={{ minHeight: !IS_LT_MD ? 500 : null }}
    >
      <div className="tc bg-sand pv6 ph6">
        <div className="dib relative mt3 mb4">
          <UserAvatar
            size="lg"
            user={{
              id: breeder.user.id,
              initials: breeder.initials,
              profile_photo_url: breeder.profile_photo_url
            }}
          />
          {isPreferredBreeder && (
            <PreferredBreederBadge
              className="absolute right-0 bottom-0"
              width="20px"
              height="22px"
              hideTooltip
            />
          )}
        </div>
        <p className="font-serif font-20 mb1">
          Raised by {breeder.display_name}
        </p>
        <p className="font-14 md:font-18 secondary">
          Certified Breeder of{' '}
          <span
            className="stone-700"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: breeder.linked_breed_names }}
          />
        </p>
      </div>
      <div className="flex flex-column justify-between pv6 ph6 flex-auto">
        <div>
          <ul className="flex flex-column gap-8 font-14 md:font-18 secondary">
            <li className="flex gap-8">
              <Icon
                className="BreederProfileDetailsCard__icon"
                name="fetch-badge-paw"
                height="20px"
                width="20px"
              />
              <span>{getBreederDescription()}</span>
            </li>
            {breederResponseDescription && (
              <li className="flex gap-8">
                <Icon
                  className="BreederProfileDetailsCard__icon"
                  name="fetch-message"
                  height="20px"
                  width="20px"
                />
                <span>{breederResponseDescription}</span>
              </li>
            )}
          </ul>
          {testimonialsCount > 0 && (
            <button
              type="button"
              className="dib bg-subtle-gray br-pill font-14 stone-700 ph4 pv2 mt4"
              onClick={() => scrollToSection(STORIES_SECTION_ID)}
            >
              {testimonialsCount} verified{' '}
              {testimonialsCount === 1 ? 'story' : 'stories'}
            </button>
          )}
        </div>
        {IS_LT_MD && availablePuppyCount > 0 && (
          <Button
            className="font-16 mt6"
            variant="outline"
            size="default"
            onClick={() => scrollToSection(PUPPIES_SECTION_ID)}
          >
            {availablePuppyCount === 1
              ? 'View available puppy'
              : `View ${availablePuppyCount} available puppies`}
          </Button>
        )}
        {!IS_LT_MD && (
          <div className="flex flex-column gap-8 mt6">
            <BreederProfileApplyButtonContainer
              className={classnames(BUTTON_VARIANTS.primary, 'font-16 pv3')}
              analytics={{
                ...COMMON_ANALYTICS_PROPS,
                view_feature: ANALYTICS_VIEW_FEATURES.APPLY_TO_BREEDER_BUTTON
              }}
            />
            <BreederProfileQuestionButton
              className={classnames(BUTTON_VARIANTS.outline, 'font-16 pv3')}
              analytics={{
                ...COMMON_ANALYTICS_PROPS,
                view_feature: ANALYTICS_VIEW_FEATURES.ASK_A_QUESTION_BUTTON
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

BreederProfileDetailsCardContainer.propTypes = {
  className: PropTypes.string
};

BreederProfileDetailsCardContainer.defaultProps = {
  className: null
};
