import React from 'react';
import PropTypes from 'prop-types';

import { USER_TYPES } from 'dpl/shared/constants/shared';
import LoginFormContainer from 'dpl/containers/LoginFormContainer';

export default function BreederProfileQuestionModalLoginForm({
  onAuth,
  onToggle
}) {
  return (
    <div className="BreederProfileQuestionModalLoginForm">
      <div className="ph10 ph18-lg">
        <LoginFormContainer
          hideForgotPassword
          onAuth={onAuth}
          userType={USER_TYPES.BUYER}
        />
      </div>
      <div className="pv6 tc bt b--light-gray">
        <span>Don&apos;t have an account?</span>&nbsp;
        <button
          className="BreederProfileQuestionModalLoginForm__toggle primary link--primary"
          onClick={onToggle}
          type="button"
        >
          Sign Up
        </button>
      </div>
    </div>
  );
}

BreederProfileQuestionModalLoginForm.propTypes = {
  onAuth: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired
};
