import React from 'react';

import SkeletonBlock from 'dpl/common/design_system/SkeletonBlock';
import { makeSequence } from 'dpl/shared/utils';

export default function BreederProfileParentsSkeleton() {
  return (
    <div className="BreederProfileParentsSection bg-subtle-light-gray pv12 pv20-md">
      <div className="container-xxl">
        <SkeletonBlock
          className="mb8 font-24 md:font-40"
          height="1em"
          width="16em"
        />
        <div className="flex gap-8 mb6 mb14-md font-16">
          {makeSequence(2).map(index => (
            <SkeletonBlock
              key={index}
              className="br-pill"
              height="3em"
              width="7em"
            />
          ))}
        </div>
        <div className="bg-white br4 pv4 pl4 pr4 pr12-md font-16">
          <div className="row wide-gutters">
            <div className="col-md-5">
              <SkeletonBlock className="br3" width="100%" height="100%" />
            </div>
            <div className="col-md-7">
              <SkeletonBlock
                className="font-20 md:font-32 mt6 mt0-md mb2"
                height="1em"
                width="8em"
              />
              <SkeletonBlock className="font-16" height="1em" width="6em" />
              <div className="mt6 mt8-md">
                <SkeletonBlock
                  className="mb3 font-16"
                  height="1em"
                  width="8em"
                />
                <SkeletonBlock className="font-16" height="6em" width="100%" />
                <SkeletonBlock
                  className="font-16 w-100 w-80-md mt6 mt8-md"
                  height="6em"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
