import React from 'react';

import SkeletonBlock from 'dpl/common/design_system/SkeletonBlock';
import { makeSequence } from 'dpl/shared/utils';

export default function BreederProfileUpdatesSkeleton() {
  return (
    <div className="BreederProfileUpdatesSkeleton container-xxl pv12 pv20-md">
      <SkeletonBlock
        className="font-24 md:font-40 lh-title mb10 mb16-md"
        height="1em"
        width="13em"
      />
      <div className="dn flex-l gap-16">
        {makeSequence(3).map(index => (
          <SkeletonBlock key={index} height="500px" width="100%" />
        ))}
      </div>
      <div className="dn flex-m gap-16">
        {makeSequence(2).map(index => (
          <SkeletonBlock key={index} height="500px" width="100%" />
        ))}
      </div>
      <div className="db dn-ns">
        <SkeletonBlock height="500px" width="100%" />
      </div>
    </div>
  );
}
