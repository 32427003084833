import React from 'react';
import PropTypes from 'prop-types';

import SmartImage from 'dpl/components/SmartImage';

import messagesFrenchieSvg from 'dpl/assets/messages_frenchie.svg';

export default function ContactModalSuccess({ children, displayName }) {
  return (
    <div className="ContactModalSuccess pv14-lg tc">
      <div className="mb4">
        <SmartImage height="154px" src={messagesFrenchieSvg} width="128px" />
      </div>
      <h5 className="mb4">Thank you!</h5>
      <p>Your message was sent.</p>
      {displayName ? (
        <p className="mb6">{displayName} will be in touch soon.</p>
      ) : (
        <p className="mb6">We&apos;ll be in touch soon.</p>
      )}
      {children}
    </div>
  );
}

ContactModalSuccess.propTypes = {
  children: PropTypes.node,
  displayName: PropTypes.string
};

ContactModalSuccess.defaultProps = {
  children: null,
  displayName: null
};
