import React from 'react';
import classnames from 'classnames';

import SkeletonBlock from 'dpl/common/design_system/SkeletonBlock';
import { makeSequence } from 'dpl/shared/utils';
import { isLessThanBreakpoint } from 'dpl/util/grid';

const IS_LT_MD = isLessThanBreakpoint('md');
const ICON_SIZE = IS_LT_MD ? '48px' : '88px';

export default function BreederProfileHealthTestsSkeleton() {
  return (
    <div className="BreederProfileHealthTestsSkeleton bg-subtle-light-gray">
      <div className="container-xxl">
        <div className="pv12 pv20-md bt b--gray-300">
          <SkeletonBlock
            className="font-24 md:font-32 mb8"
            height="1em"
            width="10em"
          />
          <SkeletonBlock
            className="font-16 br-pill mb6 mb14-md"
            height="3em"
            width="7em"
          />
          <SkeletonBlock className="font-18 br3" height="10em" width="100%" />
          <div className="pv6">
            <ul className="grid md:grid-cols-2 md:gap-x-64 mt12-md">
              {makeSequence(2).map(index => (
                <li
                  key={index}
                  className={classnames(
                    'flex flex-column flex-md-row items-center-m gap-16 md:gap-24 pv6 pv8-md bt b--gray-300',
                    {
                      bn: index === 0,
                      'bn-m bn-l': index === 1
                    }
                  )}
                >
                  <SkeletonBlock
                    className="br-100 self-start"
                    height={ICON_SIZE}
                    width={ICON_SIZE}
                  />
                  <div className="flex-auto">
                    <SkeletonBlock
                      className="font-18 md:font-20 mb2"
                      height="1em"
                      width="80%"
                    />
                    <SkeletonBlock
                      className="font-16 mb2"
                      height="3em"
                      width="100%"
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
