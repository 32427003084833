import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import SkeletonBlock from 'dpl/common/design_system/SkeletonBlock';
import { isLessThanBreakpoint } from 'dpl/util/grid';

const IS_LT_MD = isLessThanBreakpoint('md');

export default function BreederProfileDetailsCardSkeleton({ className }) {
  return (
    <div
      className={classnames(
        'BreederProfileDetailsCardSkeleton flex flex-column justify-between br4 ba b--default overflow-hidden',
        className
      )}
      style={{ height: IS_LT_MD ? 300 : 500 }}
    >
      <SkeletonBlock height="44%" />
      <div
        className="flex flex-column justify-between pv6 ph6 flex-auto"
        style={{ background: '#fbfbfb' }}
      >
        <div className="flex flex-column gap-8">
          <SkeletonBlock height="1em" width="90%" />
          <SkeletonBlock height="1em" width="70%" />
          <SkeletonBlock
            className="dib br-pill font-14 mt2"
            width="12em"
            height="2.5em"
          />
        </div>
        {IS_LT_MD ? (
          <SkeletonBlock className="font-16 mt6" height="2.5em" />
        ) : (
          <div className="flex flex-column gap-8 font-16 mt6">
            <SkeletonBlock height="2.5em" />
            <SkeletonBlock height="2.5em" />
          </div>
        )}
      </div>
    </div>
  );
}

BreederProfileDetailsCardSkeleton.propTypes = {
  className: PropTypes.string
};

BreederProfileDetailsCardSkeleton.defaultProps = {
  className: null
};
