import React from 'react';

import SkeletonBlock from 'dpl/common/design_system/SkeletonBlock';
import { makeSequence } from 'dpl/shared/utils';

export default function BreederProfileCurrentPuppiesSkeleton() {
  return (
    <div className="BreederProfileCurrentPuppiesSkeleton bg-subtle-light-gray pv12 pv20-md">
      <div className="container-xxl">
        <SkeletonBlock
          className="mb6 mb10-md font-24 md:font-40"
          height="1em"
          width="8em"
        />
        <div className="dn flex-ns gap-8">
          {makeSequence(4).map(index => (
            <SkeletonBlock
              className="BreederProfilePuppyCardSkeleton"
              key={index}
              height="314px"
            />
          ))}
        </div>

        <div className="db dn-ns">
          <SkeletonBlock className="w-100" height="314px" />
        </div>
      </div>
    </div>
  );
}
