import React from 'react';

import SkeletonBlock from 'dpl/common/design_system/SkeletonBlock';

export default function BreederProfileMoreAboutBreederItemsSkeleton() {
  return (
    <div className="BreederProfileMoreAboutBreederItemsSkeleton flex flex-column gap-40 font-16 md:font-18">
      {[7, 4, 4, 3, 3].map((height, index) => (
        <div key={index} className="flex gap-16">
          <div>
            <SkeletonBlock className="br-100" height="32px" width="32px" />
          </div>
          <SkeletonBlock
            className="font-16"
            height={`${height}em`}
            width={index === 4 ? '10em' : '100%'}
          />
        </div>
      ))}
    </div>
  );
}
