import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';

import {
  nearbyCitiesResourceDefinition,
  biggestCitiesResourceDefinition
} from './utils/queryDefinitions';
import BreederProfileLocationLinksGroup from './BreederProfileLocationLinksGroup';

export default function BreederProfileNearbyCities({
  breedName,
  breedId,
  wideLayout,
  limit,
  breederProfileId,
  isStatePage
}) {
  const { citySlug, locationTitle } = useSelector(({ server }) => server);

  const resourceDefinition = citySlug
    ? nearbyCitiesResourceDefinition(breedId, citySlug, breederProfileId)
    : biggestCitiesResourceDefinition(breedId, locationTitle);

  const { value: citiesData, isFetching } =
    useResourceQuery(resourceDefinition);

  return (
    <BreederProfileLocationLinksGroup
      breedName={breedName}
      linksData={citiesData}
      wideLayout={wideLayout}
      limit={limit}
      header={`Find ${breedName} puppies in nearby cities`}
      isStatePage={isStatePage}
      isFetching={isFetching}
    />
  );
}

BreederProfileNearbyCities.propTypes = {
  breedName: PropTypes.string.isRequired,
  breedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  wideLayout: PropTypes.bool,
  limit: PropTypes.number,
  breederProfileId: PropTypes.string,
  isStatePage: PropTypes.bool
};

BreederProfileNearbyCities.defaultProps = {
  wideLayout: false,
  limit: 20,
  breederProfileId: null,
  isStatePage: false
};
