import React from 'react';
import PropTypes from 'prop-types';

import DescriptiveBadge from 'dpl/common/components/DescriptiveBadge';

export default function BadgeList({ titles }) {
  return (
    <ul className="BadgeList flex flex-wrap f2">
      {titles.map(title => (
        <li key={title} className="mr2 mb2">
          <DescriptiveBadge label={title} />
        </li>
      ))}
    </ul>
  );
}

BadgeList.propTypes = {
  titles: PropTypes.arrayOf(PropTypes.string).isRequired
};
