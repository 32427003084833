import React from 'react';

import Tabs from 'dpl/common/design_system/Tabs';

import useBreederProfileTabs from '../../hooks/useBreederProfileTabs';

export default function BreederProfileTabs() {
  const { activeTabIndex, tabNames, onTabClick } = useBreederProfileTabs();

  return (
    <div className="BreederProfileTabs container-xxl mb6 mb12-md">
      <Tabs
        activeTabIndex={activeTabIndex}
        tabItems={tabNames}
        onTabClick={onTabClick}
        classes={{
          list: 'gap-24 md:gap-32',
          tab: 'font-16 md:font-18 fw-medium pv4',
          activeTab: 'black',
          inactiveTab: 'tertiary',
          glider: 'bb b--black'
        }}
      />
      <span
        className="db bb b--default"
        // Make active tab's 1px bottom border overlap this divider line
        style={{ marginTop: -1 }}
      />
    </div>
  );
}
