import React, { useEffect, useState, createRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { makeSequence } from 'dpl/shared/utils';

const DEFAULT_CLASSES = {
  root: null,
  list: 'gap-8',
  item: null,
  tab: 'font-16 stone-700 br-pill ph3',
  activeTab: null,
  inactiveTab: 'o-30',
  glider: 'ba br-pill b--stone-700'
};

export default function Tabs({
  className,
  activeTabIndex: initialTabIndex,
  tabItems,
  onTabClick,
  classes: initialClasses
}) {
  const [activeTabIndex, setActiveTabIndex] = useState(initialTabIndex);
  const [gliderStyle, setGliderStyle] = useState(null);

  const tabRefs = makeSequence(tabItems.length).map(createRef);
  const classes = { ...DEFAULT_CLASSES, ...initialClasses };

  useEffect(() => {
    const activeTab = tabRefs[activeTabIndex].current;
    const parentElement = activeTab.parentElement.getBoundingClientRect();
    const { left, width } = activeTab.getBoundingClientRect();

    setGliderStyle({ left: left - parentElement.left, width });
  }, [activeTabIndex]);

  return (
    <div
      className={classnames(
        'Tabs overflow-x-scroll no-scrollbars',
        classes.root,
        className
      )}
    >
      <ul className={classnames('relative flex', classes.list)}>
        {tabItems.map((label, tabIndex) => (
          <li key={tabIndex} ref={tabRefs[tabIndex]} className={classes.item}>
            <button
              type="button"
              className={classnames(
                'flex items-center pv1 nowrap',
                classes.tab,
                tabIndex === activeTabIndex
                  ? classes.activeTab
                  : classes.inactiveTab
              )}
              onClick={() => {
                setActiveTabIndex(tabItems.indexOf(label));
                onTabClick(tabIndex);
              }}
            >
              {label}
            </button>
          </li>
        ))}
        <span
          className={classnames('absolute transition h-100', classes.glider)}
          style={gliderStyle}
        />
      </ul>
    </div>
  );
}

Tabs.propTypes = {
  className: PropTypes.string,
  tabItems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.node])
  ).isRequired,
  activeTabIndex: PropTypes.number,
  onTabClick: PropTypes.func,
  classes: PropTypes.shape({
    root: PropTypes.string,
    list: PropTypes.string,
    item: PropTypes.string,
    tab: PropTypes.string,
    activeTab: PropTypes.string,
    inactiveTab: PropTypes.string,
    glider: PropTypes.string
  })
};

Tabs.defaultProps = {
  className: null,
  onTabClick: () => {},
  activeTabIndex: 0,
  classes: DEFAULT_CLASSES
};
