import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from '../components/Icon';
import { BANNER_COLORS, BANNER_ICON_VARIANTS } from './utils/constants';

const BANNER_TYPE_CLASSES = {
  short: 'inline-flex items-center max-content',
  horizontal: 'flex w-100',
  vertical: 'inline-flex flex-column max-content'
};

export default function Banner({
  children,
  className,
  color,
  iconVariant,
  iconName,
  type
}) {
  return (
    <div
      className={classnames(
        'DSBanner ba br3 pv3 pl3 pr6 font-16',
        className,
        BANNER_COLORS[color],
        BANNER_TYPE_CLASSES[type]
      )}
      style={{ gap: '16px 12px' }}
    >
      <div
        className={classnames(
          'br-100 pv2 ph2',
          BANNER_ICON_VARIANTS[iconVariant][color],
          {
            'self-start': type === 'vertical'
          }
        )}
      >
        <Icon name={iconName} />
      </div>
      <div className="default-color flex-auto">{children}</div>
    </div>
  );
}

Banner.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(Object.keys(BANNER_COLORS)),
  iconVariant: PropTypes.oneOf(Object.keys(BANNER_ICON_VARIANTS)),
  iconName: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['short', 'horizontal', 'vertical'])
};

Banner.defaultProps = {
  className: null,
  color: 'stroll',
  iconVariant: 'light',
  type: 'horizontal'
};
