import React from 'react';
import { useSelector } from 'react-redux';

import BreederProfileMoreAboutBreederItemsContainer from '../BreederProfileMoreAboutBreederItemsContainer';
import BreederProfileTrustedCardContainer from '../BreederProfileTrustedCardContainer';

export default function BreederProfileMoreAboutBreederSectionContainer() {
  const { breederFirstName } = useSelector(({ server }) => server);

  return (
    <div className="BreederProfileMoreAboutBreederSectionContainer container-xxl pv12 pv20-md">
      <h2 className="font-24 md:font-40 lh-title mb10 mb12-md">
        More about {breederFirstName}
      </h2>
      <div className="grid lg:grid-cols-2 gap-40 lg:gap-56 items-start">
        <BreederProfileMoreAboutBreederItemsContainer />
        <BreederProfileTrustedCardContainer />
      </div>
    </div>
  );
}
