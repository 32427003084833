import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { get } from 'dpl/shared/utils/object';
import { singleBreederProfileResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import BreederProfileApplyButton from 'dpl/components/BreederProfile/Show/BreederProfileApplyButton';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import useCurrentUser from 'dpl/common/hooks/useCurrentUser';
import { ANALYTICS_VIEWS } from 'dpl/constants/analytics';
import useBreederProfileTabs from 'dpl/components/BreederProfile/hooks/useBreederProfileTabs';

export default function BreederProfileApplyButtonContainer({
  onLoad,
  ...props
}) {
  const { viewFeatureLocation } = useBreederProfileTabs();

  const { breederProfileId, breederPreferredStatus } = useSelector(
    ({ server }) => server
  );

  const currentUser = useCurrentUser();

  const {
    isFetching,
    isSuccess,
    value: breederProfileShowData
  } = useResourceQuery({
    ...singleBreederProfileResourceDefinition(parseInt(breederProfileId, 10)),
    enabled: Boolean(breederProfileId)
  });

  const unsnoozedBreeds = breederProfileShowData.breeds.filter(b => !b.snoozed);

  const appliedTo = get(
    currentUser,
    'value.data.breeder_profiles_applied_to',
    []
  );
  const canApply =
    typeof currentUser.isLoggedIn === 'boolean' &&
    !appliedTo.includes(parseInt(breederProfileId, 10));

  const shouldShow = isSuccess && unsnoozedBreeds.length > 0 && canApply;

  function onClick() {
    const analyticsParams = new URLSearchParams({
      view: ANALYTICS_VIEWS.BREEDER_PROFILE,
      view_feature_location: viewFeatureLocation,
      origin_view_feature: viewFeatureLocation
    }).toString();

    window.location = `${breederProfileShowData.profile_url}/contacts/buyer_application?${analyticsParams}`;
  }

  useEffect(() => {
    if (!isFetching) {
      onLoad?.({ rendered: shouldShow });
    }
  }, [isFetching, shouldShow]);

  if (isFetching || !shouldShow) {
    return null;
  }

  return (
    <BreederProfileApplyButton
      {...props}
      breederProfileId={parseInt(breederProfileId, 10)}
      breederPreferredStatus={breederPreferredStatus}
      currentUser={currentUser}
      isLoggedIn={currentUser.isLoggedIn}
      onClick={onClick}
    />
  );
}

BreederProfileApplyButtonContainer.propTypes = {
  onLoad: PropTypes.func
};

BreederProfileApplyButtonContainer.defaultProps = {
  onLoad: null
};
