import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Pills from 'dpl/common/design_system/Pills';

export default function BreederProfileBreedFilter({
  className,
  items,
  onFilterClick,
  selectedFilterIdx
}) {
  return (
    <div className={classnames('BreederProfileBreedFilter nh6', className)}>
      <Pills
        className="ph6 overflow-x-scroll no-scrollbars flex-wrap-ns"
        items={items}
        onPillClick={onFilterClick}
        selectedPillIdx={selectedFilterIdx}
        variant="photo"
      />
    </div>
  );
}

BreederProfileBreedFilter.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      photo_url: PropTypes.string
    })
  ).isRequired,
  onFilterClick: PropTypes.func.isRequired,
  selectedFilterIdx: PropTypes.number
};

BreederProfileBreedFilter.defaultProps = {
  className: '',
  selectedFilterIdx: 0
};
