import React from 'react';
import { useSelector } from 'react-redux';

import useInfiniteCollectionQuery from 'dpl/shared/fetching/hooks/useInfiniteCollectionQuery';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { breederPostsQueryDefinition } from 'dpl/breeder_posts/utils/resourceDefinitions';
import { singleBreederProfileResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import { isLessThanBreakpoint } from 'dpl/util/grid';
import UserAvatar from 'dpl/path_to_payment/components/UserAvatar';
import Badge from 'dpl/common/design_system/Badge';
import Button from 'dpl/common/design_system/Button';
import BreederPostCard from 'dpl/breeder_posts/components/BreederPostCard';
import BreederProfileUpdatesTabSkeleton from 'dpl/components/BreederProfile/Show/skeletons/BreederProfileUpdatesTabSkeleton';
import useFollowersData from 'dpl/favorites/hooks/useFollowersData';
import {
  ANALYTICS_VIEW_FEATURE_LOCATIONS,
  ANALYTICS_VIEWS
} from 'dpl/constants/analytics';

const POSTS_PER_PAGE = 6;
const IS_LT_MD = isLessThanBreakpoint('md');

export default function BreederProfileUpdatesTabContainer() {
  const { breederProfileId } = useSelector(({ server }) => server);

  const { isLoadingFollowers, hasFollowers, numOfFollowers } =
    useFollowersData(breederProfileId);

  const { value: breeder, isFetching: isFetchingBreeder } = useResourceQuery(
    singleBreederProfileResourceDefinition(breederProfileId)
  );

  const {
    infiniteScrollItems: breederPosts,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isLoading,
    refetch
  } = useInfiniteCollectionQuery(
    breederPostsQueryDefinition(breederProfileId, {
      page: 1,
      per_page: POSTS_PER_PAGE
    })
  );

  return (
    <section className="BreederProfileUpdatesTabContainer container-xxl">
      <div className="mw7">
        {(() => {
          if (isFetchingBreeder || isLoading || isLoadingFollowers) {
            return <BreederProfileUpdatesTabSkeleton />;
          }

          return (
            <>
              <div className="flex items-center">
                <UserAvatar
                  className="flex-none"
                  user={{
                    ...breeder,
                    id: breeder.user.id,
                    profile_photo_url: breeder.avatar_profile_photo_url
                  }}
                  size={IS_LT_MD ? 'lg' : 'xl'}
                  shape="round"
                />
                <div className="ml4">
                  <h3 className="font-20 md:font-24 lh-title">
                    {breeder.display_name}
                  </h3>
                  {hasFollowers && (
                    <Badge className="mt2" color="gray">
                      {numOfFollowers} follower{numOfFollowers === 1 ? '' : 's'}
                    </Badge>
                  )}
                </div>
              </div>
              {breeder.short_description && (
                <p className="font-16 md:font-18 secondary mt4 w-100 w-90-md">
                  {breeder.short_description}
                </p>
              )}
              <div className="flex flex-column gap-16 md:gap-24 mv8 mv12-md w-100">
                <p className="font-16 fw-medium">Updates</p>
                {breederPosts.map((post, idx) => (
                  <BreederPostCard
                    key={idx}
                    analytics={{
                      view: ANALYTICS_VIEWS.BREEDER_PROFILE_PAGE,
                      view_feature_location:
                        ANALYTICS_VIEW_FEATURE_LOCATIONS.UPDATES_TAB
                    }}
                    breederPost={post}
                    refetchBreederPost={refetch}
                    isVerticalLayout
                    showLitterTag
                  />
                ))}
              </div>
              {hasNextPage && (
                <Button
                  className="font-16 stone-700 ph16 pv3 db ml-auto mr-auto w-100 w-auto-m mb12"
                  variant="outline"
                  onClick={fetchNextPage}
                  disabled={isFetching}
                >
                  Show more updates
                </Button>
              )}
            </>
          );
        })()}
      </div>
    </section>
  );
}
