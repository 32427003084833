import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { BreederProfileBreedPropTypes } from 'dpl/shared/utils/customPropTypes';
import Icon from 'dpl/common/components/Icon';

import BreedAvailabilityListItem from './BreedAvailabilityListItem';

export default function BreedAvailabilityList({
  breeds,
  breederSlug,
  programName,
  onBreedSelect
}) {
  return (
    <div className="BreedAvailabilityList">
      <ul>
        {breeds.map((breed, idx) => (
          <li
            className={classnames('pv6 b--light-gray', {
              bt: idx !== 0,
              'transition hover-bg-near-white': !breed.snoozed
            })}
            key={breed.id}
          >
            {onBreedSelect ? (
              <button
                type="button"
                onClick={() => onBreedSelect(breed)}
                disabled={breed.snoozed}
                className="db w-100"
              >
                <div className="flex align-items-center">
                  <BreedAvailabilityListItem
                    breed={breed}
                    breederSlug={breederSlug}
                    programName={programName}
                  />
                  {!breed.snoozed && (
                    <Icon name="fat-arrow-right" width="34px" height="34px" />
                  )}
                </div>
              </button>
            ) : (
              <BreedAvailabilityListItem
                breed={breed}
                breederSlug={breederSlug}
                programName={programName}
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

BreedAvailabilityList.propTypes = {
  breeds: PropTypes.arrayOf(PropTypes.shape(BreederProfileBreedPropTypes))
    .isRequired,
  onBreedSelect: PropTypes.func,
  breederSlug: PropTypes.string,
  programName: PropTypes.string
};

BreedAvailabilityList.defaultProps = {
  onBreedSelect: null,
  breederSlug: null,
  programName: null
};
